import * as React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import PlanHeader from '../plan-header';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import { mapDeliveryType } from '@coverright/utils';
import { useMemo, useState } from 'react';
import { DrugOffers } from '../drug-offers';
import Coverage from '../coverage';
import Filters from '../filters';
import { DrugTable, CoveragePhaseTable, DrugSummary } from '../drug-tables';
import moment from 'moment';
import { Chart } from '../chart';
import DiscountBanner from '../discount-banner';

type Props = {
  plan: IDrugCostBreakdownPlan;
  loading: boolean;
  onFullYearChange: (value: boolean) => void;
  fullYear: boolean;
  regulatory?: RegulatoryVariable;
};

export default function V3Breakdown(props: Props) {
  const { plan, onFullYearChange, fullYear, loading } = props;
  const [selectedDrugs, setSelectedDrugs] = useState<string[]>([]);

  const costs = useMemo(() => {
    return (
      plan?.drugCostMonthlyBreakdown?.costs
        .filter((d) => !selectedDrugs.length || selectedDrugs.includes(d.rxcui))
        .map((c) => ({
          ...c,
          numericCost:
            plan?.drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity ||
            0,
          packageName:
            plan?.drugPrices.find((p) => p.rxcui === c.rxcui)
              ?.packageDescription || c.packageName,
        })) || []
    );
  }, [plan, selectedDrugs]);

  const drugPrices = useMemo(() => {
    return (
      plan?.drugPrices.filter(
        (d) => !selectedDrugs.length || selectedDrugs.includes(d.rxcui)
      ) || []
    );
  }, [plan, selectedDrugs]);

  return (
    <Card
      sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
      data-test={'drug-cost-breakdown'}
    >
      <PlanHeader plan={plan} />
      <DiscountBanner />
      <DrugOffers
        drugPrices={plan.drugPrices}
        discountedDrugs={plan.discountedDrugs}
      />

      <Stack spacing={2}>
        <Typography className={'fs-24 epilogue semi-bold'}>
          Yearly Estimation Coverage
        </Typography>
        <Typography sx={{ color: '#666666' }}>
          Estimated Average Monthly Cost : {plan?.drugsCost}
        </Typography>
        <Filters
          plan={plan}
          selectedDrugs={selectedDrugs}
          onFullYearChange={onFullYearChange}
          fullYear={fullYear}
          onChange={setSelectedDrugs}
        />
        <Chart
          costs={costs}
          monthlyCosts={plan.drugCostMonthlyBreakdown?.monthlyCosts || []}
          loading={loading}
        />
      </Stack>

      <Coverage plan={plan} />

      <Stack spacing={2}>
        <Typography className={'fs-24 epilogue semi-bold'}>Drugs</Typography>

        {plan.quoteDrugsTiers?.pharmacyName && (
          <Typography sx={{ color: '#666666' }}>
            Your pharmacy: {plan.quoteDrugsTiers?.pharmacyName} |{' '}
            {mapDeliveryType(plan?.quoteDrugsTiers?.deliveryType)}
          </Typography>
        )}

        <DrugTable drugPrices={drugPrices} />
      </Stack>

      <Stack spacing={2}>
        <Typography className={'fs-24 epilogue semi-bold mt-16'}>
          By coverage phases
        </Typography>
        <CoveragePhaseTable drugPrices={drugPrices} />
        <div>
          <Typography className={'semi-bold'}>Deductible</Typography>
          <Typography sx={{ color: '#666666' }}>
            Estimated full retail price<sup>1</sup>
          </Typography>
        </div>
        <div>
          <Typography className={'semi-bold'}>Initial coverage</Typography>
          <Typography sx={{ color: '#666666' }}>
            Minimum of retail price and plan copay/ coinsurance (based on tier)
            <sup>2</sup>
          </Typography>
        </div>
        <div>
          <Typography className={'semi-bold'}>
            Coverage gap ("Donut hole")
          </Typography>
          <Typography sx={{ color: '#666666' }}>
            Typically {props.regulatory?.coverageGapCoinsurance || 0}% of retail
            (both branded and generic)<sup>3</sup>
          </Typography>
        </div>
        <div>
          <Typography className={'semi-bold'}>Catastrophic coverage</Typography>
          <Typography sx={{ color: '#666666' }}>
            Greater of {props.regulatory?.catastrophicCoinsurance || 0}% or $
            {props.regulatory?.catastrophicCopayGeneric || 0} (generics) / $
            {props.regulatory?.catastrophicCopayBranded || 0} (branded)
            <sup>4</sup>
          </Typography>
        </div>
      </Stack>

      <Stack spacing={2}>
        <Typography className={'fs-24 epilogue semi-bold'}>
          Summary by month
        </Typography>
        <DrugSummary
          costs={costs}
          monthlyCosts={plan?.drugCostMonthlyBreakdown?.monthlyCosts}
          total={plan?.drugCostMonthlyBreakdown?.totalClientCost}
        />
        <div>
          <Typography className={'semi-bold'}>Last update</Typography>
          <Typography sx={{ color: '#666666' }}>
            {moment().format('MM/DD/YYYY')}
            <br />
            MULTIPLAN_COVERRIGHT_2024_HP_M
          </Typography>
        </div>
      </Stack>
    </Card>
  );
}
