import React from 'react';
import { Box, Rating, Typography } from '@mui/material';
import { Star } from '@phosphor-icons/react';
import { CRTooltip } from '@coverright/ui/inputs';

interface StarRatingProps {
  plan?: { rating?: number | null, ratingNote?: string | null },
  size?: number,
  endAdornment?: JSX.Element,
  labelClassName?: string
}

export function StarRating({plan, size, endAdornment, labelClassName}: StarRatingProps) {
  if (!plan || !plan.rating && !plan.ratingNote) {
    return null;
  }

  const rating = !plan.rating ? <Typography sx={{color: '#4D4D4D'}} className={`no-wrap medium fs-14 ${labelClassName || ''}`} >{plan.ratingNote}</Typography>
    : <Rating emptyIcon={<Star size={size || 24} color="#1e92a0" weight="duotone" />}
              icon={<Star size={size || 24} color="#1e92a0" weight="fill" />}
              defaultValue={plan.rating || 0}
              precision={.5}
              readOnly
    />;

  return <CRTooltip arrow title={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}>
    <Box sx={{width: 'fit-content'}} className={'flex flex-align-center'}>
      {rating}
      {endAdornment}
    </Box>
  </CRTooltip>;
}
