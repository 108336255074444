import { Button, Card, Collapse, IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { PlanFilterDataContext } from './filters/PlanFilterDataContext';
import { PlanFilterContext } from './filters/PlanFilterContext';
import * as _ from 'lodash';
import { areComponentPropsEqual, getQuoteId, useDebouncedEffect } from '@coverright/utils';
import { useNavigate } from 'react-router';
import paths from '../../../../config/router-paths';
import { CRTooltip } from '@coverright/ui/inputs';
import {
  DrugOutput,
  ExtraBenefit,
  Field,
  Plan,
  ProviderOutput,
  Rating,
  SnpType
} from '@coverright/data-access/types/medicare';
import { useIsTherePlanWithFullDrugCoverage, useMedicarePlans } from '@coverright/data-access/medicare';
import { QuoteContext } from '@coverright/shared/contexts';
import { Sort } from '@coverright/data-access/types/medigap';

interface MessagesProps {
  total?: number,
  plans: Plan[],
  loading?: boolean,
  zip?: string,
  countyName?: string,
  planTypes?: string[],
  SNPTypes?: SnpType[],
  rating?: Rating[],
  extraBenefits?: ExtraBenefit[],
  preferredDrugs?: DrugOutput[],
  preferredDoctors?: ProviderOutput[],
}

function Func(props: MessagesProps) {
  const navigate = useNavigate();
  const [noPlansOpen, setNoPlansOpen] = React.useState(false);
  const [changeBenefitsOpen, setChangeBenefitsOpen] = React.useState(false);
  const [notAllDoctorsWithPpoOpen, setNotAllDoctorsWithPpoOpen] = React.useState(false);
  const [notAllDoctorsWithoutPpoOpen, setNotAllDoctorsWithoutPpoOpen] = React.useState(false);
  const [starRatingOpen, setStarRatingOpen] = React.useState(false);
  const [notAllDrugsOpen, setNotAllDrugsOpen] = React.useState(false);
  const [noPpoDsnpOpen, setNoPpoDsnpOpen] = React.useState(false);
  const [notingSelectedOpen, setNotingSelectedOpen] = React.useState(false);
  const [noDrugsSelectedOpen, setNoDrugsSelectedOpen] = React.useState(false);
  const [noDoctorsSelectedOpen, setNoDoctorsSelectedOpen] = React.useState(false);
  const [noDoctorsStatusOpen, setNoDoctorsStatusOpen] = React.useState(false);
  const {planFilterData} = React.useContext(PlanFilterDataContext);
  const {planYear} = React.useContext(QuoteContext);
  const [totalPlans, setTotalPlans] = React.useState(0);
  const [isTherePlanWithFullDrugCoverage, setIsTherePlanWithFullDrugCoverage] = React.useState(true);
  const [drugCoverageRequest] = useIsTherePlanWithFullDrugCoverage({
    onCompleted: data => setIsTherePlanWithFullDrugCoverage(data.isTherePlanWithFullDrugCoverage),
    fetchPolicy: 'no-cache',
    variables: {
      zip: props.zip as string,
      countyName: props.countyName as string,
      quoteId: getQuoteId(),
      planYear,
    }
  });
  const [request] = useMedicarePlans({
    onCompleted: data => {
      setTotalPlans(data.plans.totalElements)
      setChangeBenefitsOpen(true);
    }
  })

  const isPpoAvailable = React.useMemo(() => {
      return planFilterData?.planTypes?.find(i => i.value === 'PPO')?.label !== 'PPO';
  }, [planFilterData]);

  useDebouncedEffect(() => {
    if (!props.loading) {
      const planCoversAllDoctorsExists = props.plans.some(p => p.doctorsCoverage.length && p.doctorsCoverage.every(c => c.coverage));

      if (_.isEqual(props.planTypes, ["HMO"]) && !props.SNPTypes?.length && props.plans.some(p => p.doctorsCoverage.length) && !planCoversAllDoctorsExists) {
        setNotAllDoctorsWithPpoOpen(isPpoAvailable);
        setNotAllDoctorsWithoutPpoOpen(!isPpoAvailable);
      } else {
        setNotAllDoctorsWithPpoOpen(false);
        setNotAllDoctorsWithoutPpoOpen(false);
      }

      if (_.isEqual(props.SNPTypes, ["D_SNP"]) && !props.plans.some(p => p.planType === 'PPO') && props.preferredDoctors?.length) {
        setNoPpoDsnpOpen(!props.plans.some(p => p.planType === 'PPO'));
      } else {
        setNoPpoDsnpOpen(false);
      }

      if (props.preferredDrugs?.length) {
        setNotAllDrugsOpen(!isTherePlanWithFullDrugCoverage);
      } else {
        setNotAllDrugsOpen(false);
      }

      if (props.rating?.length && !props.rating?.includes(Rating.None) && planFilterData?.starRatings?.find(i => i.value === 'NONE')?.label !== 'Not rated') {
        setStarRatingOpen(true);
      } else {
        setStarRatingOpen(false);
      }

      const nothingSelected = !props.preferredDrugs?.length && !props.preferredDoctors?.length

      setNotingSelectedOpen(nothingSelected);

      if (!nothingSelected) {
        if (!props.preferredDrugs?.length) {
          setNoDrugsSelectedOpen(true);
        } else {
          setNoDrugsSelectedOpen(false);
        }

        if (!props.preferredDoctors?.length) {
          setNoDoctorsSelectedOpen(true);
        } else {
          setNoDoctorsSelectedOpen(false);
        }
      } else {
        setNoDrugsSelectedOpen(false);
        setNoDoctorsSelectedOpen(false);
      }

      if (!localStorage.getItem('visited') && props.plans.length < 3 && props.extraBenefits?.filter(b => b !== ExtraBenefit.DrugCoverage && b !== ExtraBenefit.PartBPremiumGiveback).length) {
        request({
          variables: {
            filter: {
              zip: props.zip as string,
              countyName: props.countyName as string,
              SNPTypes: props.SNPTypes,
              extraBenefits: props.extraBenefits?.includes(ExtraBenefit.DrugCoverage) ? [ExtraBenefit.DrugCoverage] : [],
            },
            page: {
              size: 1,
              page: 0
            },
            quoteId: getQuoteId(),
            sort: [
              { "field": Field.Rating,"direction":Sort.Desc }
            ]
          }
        })
      }
    }

    if (props.preferredDoctors?.length) {
      const allDoctorsStatus = props.preferredDoctors?.reduce((prev: boolean, current: ProviderOutput) => {
        return props.plans.some(plan => plan.doctorsCoverage.some(cov => cov.npi === current.npi && cov.coverage !== null && typeof cov.coverage !== 'undefined'))
      }, true)

      setNoDoctorsStatusOpen(!allDoctorsStatus);
    } else {
      setNoDoctorsStatusOpen(false);
    }


  }, 500, [ props.plans, isPpoAvailable, props.loading, props.preferredDrugs, props.preferredDoctors, isTherePlanWithFullDrugCoverage]);

  React.useEffect(() => {
    if (props.preferredDrugs?.length) {
      drugCoverageRequest()
    }
  }, [props.preferredDrugs]);

  React.useEffect(() => {
    return () => {
      localStorage.setItem('visited', 'true');
    }
  }, []);

  React.useEffect(() => {
    setNoPlansOpen(props.total === 0);
  }, [props.total]);

  if (props.loading) {
    return null;
  }

  return <Stack>
    <Collapse in={noPlansOpen}>
      <Message title={'Oops!  There are no plans that fit your criteria.'}
               description={'This could be because you have applied too many filters. Try reducing the number of filters or click ‘Reset filters’ to reset (this will not impact your drug or doctor inputs).'}
               />
    </Collapse>
    <Collapse in={notingSelectedOpen}>
      <Message title={'You haven’t entered your doctors & drugs'}
               description={'It’s important to enter your doctors and drugs that you want covered before comparing any plans. This allows us to (a) show plans that will cover your doctors; and (b) calculate your drug costs (which can vary significantly between different plans).'}
               extra={<Button sx={{mt: 1}} onClick={() => navigate(`${paths.editPreferences + props.zip}/${props.countyName}`)} size={'small'} variant={'outlined'}>Enter your information</Button>}
               />
    </Collapse>
    <Collapse in={noDoctorsStatusOpen}>
      <Message title={'We are unable to search the network status for some of your doctors'}
               description={'It looks like one or more of your doctors is not currently in our network database (this is denoted by a the grey “X” icon next to the doctor’s name). If this doctor is important to you please connect with one of our licensed advisors and they can conduct a more detailed search prior to selecting a plan.'}
               onClose={() => setNoDoctorsStatusOpen(false)}
      />
    </Collapse>
    <Collapse in={noDoctorsSelectedOpen}>
      <Message title={'You haven’t entered your doctors'}
               description={'It’s important to enter your doctors that you want covered before comparing any plans. This allows us to show plans that will cover your doctors.'}
               extra={<Button sx={{mt: 1}} onClick={() => navigate(`${paths.editPreferences + props.zip}/${props.countyName}`)} size={'small'} variant={'outlined'}>Enter your information</Button>}
               onClose={() => setNoDoctorsSelectedOpen(false)}
      />
    </Collapse>
    <Collapse in={noDrugsSelectedOpen}>
      <Message title={'You haven’t entered your drugs'}
               description={'It’s important to enter your doctors and drugs that you want covered before comparing any plans. This allows us to (a) show plans that will cover your doctors; and (b) calculate your drug costs (which can vary significantly between different plans).'}
               extra={<Button sx={{mt: 1}} onClick={() => navigate(`${paths.editPreferences + props.zip}/${props.countyName}/drugs`)} size={'small'} variant={'outlined'}>Enter your information</Button>}
               onClose={() => setNoDrugsSelectedOpen(false)}
               />
    </Collapse>
    <Collapse in={changeBenefitsOpen}>
      <Message title={`It looks like there aren’t ${props.plans.length ? 'many' : 'any'} plan matches based on your extra benefit selections.`}
               description={<>There are <b>{totalPlans} plans</b> in your area overall, however, there {props.plans.length ? (props.plans?.length > 1 ? 'are' : `is`) + ` only ${props.plans.length}` : 'no'} plan{props.plans?.length > 1 ? 's' : ' that'} matches based on the extra benefits you have selected. To see more plans remove any extra benefit that are not crucial.</>}
               onClose={() => {
                 localStorage.setItem('visited', 'true');
                 setChangeBenefitsOpen(false)
               }}
               />
    </Collapse>
    <Collapse in={notAllDoctorsWithPpoOpen}>
      <Message title={'Not all your doctors are covered'}
               description={<>You are currently only viewing <CRTooltip placement={'bottom'} arrow title={'An HMO (Health Maintenance Organization) plan is a type of Medicare Advantage plan. HMO plan enrollees must use doctors and hospitals within a plan’s specific network to receive their covered services except for emergency situations.'}><span className={'underline medium'}>HMO</span></CRTooltip> plans, however, it appears that not all your doctors are covered by any plan.  If you must keep all your doctors we would recommend looking at <CRTooltip placement={'bottom'} arrow title={'A PPO (Preferred Provider Organization) plan is a type of Medicare Advantage plan.   PPO plan enrollees can use doctors and hospitals within a plan’s \'preferred\' network to achieve a lower cost of care.  PPO enrollees also have the flexibility to see out-of-network providers, however, you will likely pay a higher copayment or coinsurance for seeing doctors outside the plans’ preferred provider list.'}><span className={'underline medium'}>PPO</span></CRTooltip> plans. These plans may cost more overall than a HMO plan.</>}
               onClose={() => setNotAllDoctorsWithPpoOpen(false)}
               />
    </Collapse>
    <Collapse in={notAllDoctorsWithoutPpoOpen}>
      <Message title={'Not all your doctors are covered'}
               description={<>You are currently only viewing <CRTooltip placement={'bottom'} arrow title={'An HMO (Health Maintenance Organization) plan is a type of Medicare Advantage plan. HMO plan enrollees must use doctors and hospitals within a plan’s specific network to receive their covered services except for emergency situations.'}><span className={'underline medium'}>HMO</span></CRTooltip> plans, however, it appears that not all your doctors are covered by any plan.  If you must keep your doctors, we recommend looking at <span className={'underline medium'}>Medicare Supplement</span> options (as there are no or limited <CRTooltip placement={'bottom'} arrow title={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}><span className={'underline medium'}>PPO</span></CRTooltip> Medicare Advantage plans in your area).</>}
               onClose={() => setNotAllDoctorsWithoutPpoOpen(false)}
               />
    </Collapse>
    <Collapse in={noPpoDsnpOpen}>
      <Message title={'Not all your doctors are covered'}
               description={<>You are currently only viewing <CRTooltip placement={'bottom'} arrow title={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific needs. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve.  Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid.'}><span className={'underline medium'}>D-SNP</span></CRTooltip> plans. Most <CRTooltip placement={'bottom'} arrow title={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific needs. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve.  Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid.'}><span className={'underline medium'}>D-SNP</span></CRTooltip> plans require you to use a network, however, you get the benefit of a special plan designed to coordinate your Medicare and Medicaid benefits into one simple plan with minimal out-of-pocket costs.  We recommend finding a plan that covers at least your primary care doctor. If you must keep all your doctors you can consider a <CRTooltip placement={'bottom'} arrow title={'A PPO (Preferred Provider Organization) plan is a type of Medicare Advantage plan.   PPO plan enrollees can use doctors and hospitals within a plan’s \'preferred\' network to achieve a lower cost of care.  PPO enrollees also have the flexibility to see out-of-network providers, however, you will likely pay a higher copayment or coinsurance for seeing doctors outside the plans’ preferred provider list.'}><span className={'underline medium'}>PPO</span></CRTooltip> plan. </>}
               onClose={() => setNoPpoDsnpOpen(false)}
               />
    </Collapse>
    <Collapse in={notAllDrugsOpen}>
      <Message title={'No plan in your area covers all your drugs'}
               description={<>There are no plans in your area that cover all the prescription drugs you have entered. Please reach out to us. Your dedicated advisor will discuss your situation and help you navigate next steps.</>}
               onClose={() => setNotAllDrugsOpen(false)}
               />
    </Collapse>
    <Collapse in={starRatingOpen}>
      <Message title={'Some plans are too new to be given a star rating.'}
               description={<>Your current filter settings will not show plans yet to be given a <CRTooltip placement={'bottom'} arrow title={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}><span className={'underline medium'}>Star Rating</span></CRTooltip>. The Centers for Medicare & Medicaid Services (CMS) provides <CRTooltip placement={'bottom'} arrow title={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}><span className={'underline medium'}>Star Ratings</span></CRTooltip> for plans each year. However, if a plan is new to your area it might not yet be awarded a star-rating.  Not having a star rating yet does necessarily imply the plan is low quality.</>}
               onClose={() => setStarRatingOpen(false)}
               />
    </Collapse>
  </Stack>
}

export default React.memo(Func, areComponentPropsEqual)

interface MessageProps {
  title: string,
  description: string | JSX.Element,
  extra?: JSX.Element,
  onClose?: () => void,
}

const Message = (props: MessageProps) => {
  return <Card sx={{flexDirection: 'row', mb: 2, borderRadius: '10px', gap: 1, alignItems: 'flex-start', p: '10px 8px 10px 14px'}}>
    <img src={'/assets/img/big-green-info.svg'} className={'w-32 h-32'} />
    <div>
      <Typography className={'bold fs-18 lh-22'}>{props.title}</Typography>
      <Typography className={'fs-14 lh-17'}>{props.description}</Typography>
      <Typography className={'fs-14 lh-17'}>{props.extra}</Typography>
    </div>
    {props.onClose && <IconButton onClick={props.onClose}>
      <img src={'/assets/img/close.svg'} className={'w-30 h-30'} />
    </IconButton>}
  </Card>
}
