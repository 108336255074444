import { SxProps } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { Check } from '@phosphor-icons/react/dist/ssr/Check';
import React from 'react';

export const TickAdornment = ({sx}: {sx?: SxProps}) => (
  <InputAdornment sx={sx}
                  disablePointerEvents
                  position="end">
    <Check size={24} color="#0B741C" weight="regular" />
  </InputAdornment>
);
