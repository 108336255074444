import React from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';
import { convertBold, convertList } from '@coverright/utils';
import { SxProps } from '@mui/material';

export type ListPlanDetailProps = {
  title: string,
  titleClassName?: string,
  imgClassName?: string,
  tooltip?: string,
  content: (string | JSX.Element)[],
  dividerSx?: SxProps,
  thin?: boolean,
  hideDivider?: boolean,
  sx?: SxProps,
}

export function ListPlanDetail({title, content, thin, tooltip, dividerSx, hideDivider, titleClassName, imgClassName, sx}: ListPlanDetailProps) {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, my: {md: "10px"}, alignItems: "flex-start", ...sx}}>
        <Box sx={{minWidth: {md: '50%'}, width: {md: '50%'}}}>
          {!tooltip && <Typography variant={"h5"} color={'textPrimary'} className={titleClassName}>
            {!thin && <strong>{title}</strong>}
            {!!thin && title}
          </Typography>}
          {tooltip && <div className={`flex`}>
            <CRTooltip placement={'top'} arrow title={tooltip}>
              <div className={'flex flex-align-start'}>
                <img className={`mt-5 mr-8 ${imgClassName || ''}`} src={'/assets/img/info.svg'}></img>
                <Typography sx={{
                  fontSize: '16px',
                  mt: '4px',
                  mr: '8px',
                  fontWeight: '700',
                }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>
              </div>
            </CRTooltip>
          </div>}
        </Box>
        <Box display={"flex"} mt={{xs: '5px', md: 0}}>
          <ListPlanDetailText content={content} className={phone ? 'ml-24' : undefined} />
        </Box>
      </Box>
      {!hideDivider && <Divider sx={{my: {xs: 2, md: 3}, ...dividerSx}}/>}
    </>
  )
}

export const ListPlanDetailText = ({content, className}: { content: (string | JSX.Element)[], className?: string }) => (
  <Box display={"flex"} flexGrow={2} flexDirection={"column"} className={className}>
    {( !content || content.length == 0 ) &&
    <Typography variant={"h5"} color={'textPrimary'}>Not covered</Typography>
    }
    {(!!content && content.length > 0 ) && content.map((line, i) =>
      <Box key={i} minHeight={'18px'}>
        <Typography className={'fs-12 lh-20'} color={'textPrimary'}>
          {typeof line === 'string' && <span dangerouslySetInnerHTML={{__html: convertList(convertBold(line))}}></span>}
          {typeof line !== 'string' && <>{line}</>}
        </Typography>
      </Box>
    )}
  </Box>
)
