import React from "react";
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import {CaretDown} from '@phosphor-icons/react';

interface Props {
  sx?: SxProps,
  expanded: boolean,
  onToggle: (expanded: boolean) => void,
  getTitle?: (expanded: boolean) => string,
}

export const SeeMore = ({expanded, onToggle, sx, getTitle}: Props) => (
  <Box sx={sx} className={'flex flex-align-center pointer'} justifyContent={'flex-start'} onClick={() => onToggle(expanded)}>
    <Typography sx={{fontSize: 11, mr: .5}}>
      {getTitle ? getTitle(expanded) : `See ${!expanded ? 'more' : 'less'}`}
    </Typography>
    <Box sx={{
      transform: expanded ? 'rotate(-180deg) !important' : 'rotate(0)',
      transition: 'all 0.3s',
      display: 'flex',
      alignItems: 'center',
    }}>
      <CaretDown size={10} color={'#1C434F'} weight={'bold'} />
    </Box>
  </Box>
)
