import {useEffect, useState} from "react";

export const useDebouncedMemo = <T>(input: T, delay:number) => {
  const [value, setValue] = useState<T>(input);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(input);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [input, delay]);

  return value;
}
