import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { QueryPlansCountByZipArgs, PlansCountByZipOutput } from '@coverright/data-access/types/medicare';

export function usePlansCountByZip(options?: LazyQueryHookOptions<{plansCountByZip: PlansCountByZipOutput}, QueryPlansCountByZipArgs>) {
  return useLazyQuery<{plansCountByZip: PlansCountByZipOutput}, QueryPlansCountByZipArgs>(
    gql(request),
    {
      fetchPolicy: 'no-cache',
      ...options
    }
  );
}


const request = `
query ($zip: String!) {
  plansCountByZip(zip: $zip) {
    maPlansCount
    maPlansTotalCount
    maPlansParentOrgCount
    maPlansTotalParentOrgCount
    pdpPlansParentOrgCount
    pdpPlansTotalParentOrgCount
    pdpPlansCount
    pdpPlansTotalCount
    zip
  }
}
`;
