import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { MapPin } from '@phosphor-icons/react';

type MarkerProps = {
  index: number;
  clickable?: boolean;
  lat?: number;
  lng?: number;
  active?: boolean;
};

const Marker = (props: MarkerProps) => {
  return (
    <Box
      position={'relative'}
      width={'40px'}
      zIndex={props.active ? 1 : undefined}
    >
      <Box
        sx={{
          width: 1,
          position: 'absolute',
          mt: '6px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 20,
            height: 20,
            borderRadius: '10px',
            backgroundColor: 'white',
          }}
        >
          <Typography
            sx={{ color: props.active ? '#0B741C' : '#094451' }}
            className={'bold fs-12 lh-12'}
            align={'center'}
          >
            {props.index}
          </Typography>
        </Box>
      </Box>
      <MapPin
        size={40}
        color={props.active ? '#0B741C' : '#094451'}
        weight="fill"
        className={'w-40'}
      />
    </Box>
  );
};

export default Marker;
