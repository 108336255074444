import React from "react";
import * as _ from 'lodash';

interface ComparisonContextState {
  isInCompare: (bidId: string) => boolean,
  removeFromCompare: (bidId: string) => void,
  addToCompare: (bidId: string) => void,
  clear: () => void,
  hideError: () => void,
  list: string[],
  error: boolean
}

const defaultState: ComparisonContextState = {
  isInCompare: () => false,
  removeFromCompare: () => {},
  addToCompare: () => {},
  clear: () => {},
  hideError: () => {},
  list: [],
  error: false
};

export const ComparisonContext = React.createContext<ComparisonContextState>(defaultState);

export function ComparisonProvider(props: React.PropsWithChildren<ComparisonProviderProps>) {

  const [list, setList] = React.useState<string[]>(getCompareList());
  const [showOvercountError, setShowOvercountError] = React.useState(false);

  const remove = (bidId: string) => {
    removeFromCompare(bidId);
    setList(getCompareList())
  }

  const add = (bidId: string) => {
    if (list.length === 6) {
      setShowOvercountError(true);
      return
    }
    addToCompare(bidId);
    setList(getCompareList())
  }

  const isInCompare = (bidId: string) => list.includes(bidId);

  const clear = () => {
    for (const id of list) {
      remove(id);
    }
  };

  return <ComparisonContext.Provider value={{
    error: showOvercountError,
    clear,
    removeFromCompare: remove,
    addToCompare: add,
    isInCompare,
    list,
    hideError: () => setShowOvercountError(false)
  }}>
    {props.children}
  </ComparisonContext.Provider>

}

type ComparisonProviderProps = {}

const COMPARISON_KEY = 'COMPARISON';

export function addToCompare(bidId: string) {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  const stored: string[] = storedValue ? JSON.parse(storedValue) : [];
  removeFromCompare(bidId);
  if (stored.length < 6) {
    //stored.splice(5, 1);
    sessionStorage.setItem(COMPARISON_KEY, JSON.stringify([...stored, bidId]));
  }
}

export function removeFromCompare(bidId: string) {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  if (!storedValue) {
    return;
  }
  const stored: string[] = JSON.parse(storedValue);
  sessionStorage.setItem(COMPARISON_KEY, JSON.stringify(_.without(stored, bidId)));
}

export function getCompareList(): string[] {
  const storedValue = sessionStorage.getItem(COMPARISON_KEY);
  return storedValue ? JSON.parse(storedValue) : [];
}
