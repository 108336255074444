import * as React from 'react';
import { GetNextPlansYear } from '@coverright/utils';
import { useState } from 'react';
import { V3Preloader } from '@coverright/ui/shared';
import Breakdown from './components/breakdown';
import { Props } from '../lib/types';
import useRegulatoryVariable from '../api/use-regulatory-variable';
import { useMaPlanDiscountsQuery } from '@coverright/data-access/medicare';
import { Card, Typography } from '@mui/material';

export function MADrugCostBreakdown(props: Props & { quoteId?: string }) {
  const [fullYear, setFullYear] = useState(
    props.planYear === GetNextPlansYear()
  );
  const regulatory = useRegulatoryVariable(props.planYear);
  const { data, loading, previousData } = useMaPlanDiscountsQuery({
    variables: { ...props, fullYear },
  });

  const discounts = data?.maPlanDiscounts || previousData?.maPlanDiscounts;

  if (!discounts && loading) {
    return <V3Preloader sx={{ mt: 4, mb: 6 }} />;
  }

  if (!discounts?.drugPrices?.length) {
    return (
      <Card>
        <Typography>
          Looks like something wrong. Please try again later
        </Typography>
      </Card>
    );
  }

  return (
    <Breakdown
      plan={discounts}
      loading={loading}
      fullYear={fullYear}
      regulatory={regulatory}
      onFullYearChange={setFullYear}
    />
  );
}
