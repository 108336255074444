import React from "react";
import MonthlyCostCalculator from "./MonthlyCostCalculator";
import { Box, Button, Card, IconButton } from '@mui/material';
import PlanFilter from './filters/PlanFilter';
import HealthcarePreferences from './HealthcarePreferences';
import { colors } from '@coverright/ui/themes';
import { useNavigate, useParams } from 'react-router';;
import { MarketplaceLayoutContext } from '../../../components/MarketplaceLayout';

const PlanListSideContent = () => {
  const navigate = useNavigate();
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const {setDrawerOpen} = React.useContext(MarketplaceLayoutContext);
  const [showMenu, setShowMenu] = React.useState(false);

  const onBackClick = React.useCallback(() => {
    if (showMenu) {
      navigate(`/plans/${zip}/${countyName}`)
      setShowMenu(false)
      setDrawerOpen(false)
    } else {
      setShowMenu(true)
    }
  }, [showMenu])

  return <Box sx={{minWidth: 304, maxWidth: 304, display: 'flex', flexDirection: 'column'}}>
    <Box sx={{display: {xs: 'flex', lg: 'none'}, alignItems: 'center', pt: 2, mx: '-4px', px: '4px', justifyContent: 'space-between', position: 'sticky', top: 0, zIndex: 2, background: '#F5F7F6'}}>
      <Button
        sx={{color: colors.text.primary}}
        startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
        onClick={onBackClick}>
        Back to {showMenu ? 'plans' : 'menu'}
      </Button>
      <IconButton onClick={() => {
        setShowMenu(false)
        setDrawerOpen(false)
      }}>
        <img src={'/assets/img/icon-close.svg'} className={'w-16 h-16 op-5'}/>
      </IconButton>
    </Box>
    <Card sx={{p: 3, mt: {xs: '12px', lg: 0}}}>
      <PlanFilter />
    </Card>
    <Card sx={{p: 3, mt: 3}}>
      <HealthcarePreferences />
    </Card>
    <Card sx={{p: 3, mt: 3}}>
      <MonthlyCostCalculator />
    </Card>
  </Box>
}

export default PlanListSideContent;
