import React from 'react';
import { Box, Typography } from '@mui/material';
import { getStoredUser } from '@coverright/utils';
import { UpdateZipModal } from './update-zip-modal';
import { useIsV3Theme } from '@coverright/features';

interface Props {
  loading?: boolean;
  plansTotal?: number;
  zip?: string | null;
  county?: string | null;
  onChange: (zip: string, county: string) => void;
}

export function UpdateZip({
  loading,
  plansTotal,
  zip,
  onChange,
  county,
}: Props) {
  const [updateZipModalOpen, setUpdateZipModalOpen] = React.useState(false);
  const isV3 = useIsV3Theme();
  const Content = isV3 ? V3 : Legacy;

  const onUpdateZipModalClose = (
    newZip?: string,
    newCounty?: string,
    newState?: string
  ) => {
    if (newZip && newCounty && (newCounty !== county || newZip !== zip)) {
      onChange(newZip, newCounty);
    }
    setUpdateZipModalOpen(false);
  };

  return (
    <>
      {updateZipModalOpen && (
        <UpdateZipModal
          open={updateZipModalOpen}
          onClose={onUpdateZipModalClose}
          zip={zip}
          county={county}
        />
      )}

      {loading && (
        <Typography className={'epilogue fs-24 lh-40 semi-bold'}>
          {' '}
          Loading...{' '}
        </Typography>
      )}
      {!loading && (
        <Content
          zip={zip}
          county={county}
          plansTotal={plansTotal}
          onClick={() => setUpdateZipModalOpen(true)}
        />
      )}
    </>
  );
}

interface ContentProps {
  plansTotal?: number;
  zip?: string | null;
  county?: string | null;
  onClick: () => void;
}

const V3 = ({ zip, county, plansTotal, onClick }: ContentProps) => (
  <Typography className={'epilogue fs-24 lh-40 semi-bold'}>
    {plansTotal} Plans found in{' '}
    <Box
      component={'span'}
      onClick={onClick}
      sx={{
        color: '#1C434F',
        cursor: 'pointer',
        borderBottom: '1px solid #1C434F',
      }}
    >
      {zip} {county} County, {getStoredUser()?.state}
    </Box>
  </Typography>
);

const Legacy = ({ zip, county, onClick }: ContentProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: 1 }}>
    <Typography className={'fs-16 lh-20 medium'}>Showing plans for:</Typography>
    <Box
      ml={1}
      className={'pointer'}
      display={'flex'}
      alignItems={'center'}
      onClick={onClick}
    >
      <Typography className={'fs-16 lh-20 bold'}>
        {zip} {county}
      </Typography>
      <Box ml={1} pt={'5px'}>
        <img src={'/assets/img/edit.svg'} />
      </Box>
    </Box>
  </Box>
);
