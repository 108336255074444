import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A type representing BigDecimal */
  BigDecimal: { input: any; output: any; }
  /** A type representing local date */
  LocalDate: { input: any; output: any; }
  /** A type representing long */
  Long: { input: any; output: any; }
  /** A type representing a string identifier */
  UUID: { input: any; output: any; }
};

export enum CalcDrugFrequencyPeriod {
  Daily = 'DAILY',
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export enum CsgGender {
  F = 'F',
  M = 'M'
}

export enum Gender {
  F = 'F',
  M = 'M'
}

export type MedigapCompanyFilterItem = {
  __typename?: 'MedigapCompanyFilterItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  naics: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type MedigapCompanyOutput = {
  __typename?: 'MedigapCompanyOutput';
  fullName: Scalars['String']['output'];
  key: Scalars['String']['output'];
  naic: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCompanyName: Scalars['String']['output'];
};

export enum MedigapDrugDeliveryType {
  Both = 'BOTH',
  Mail = 'MAIL',
  Pharmacy = 'PHARMACY'
}

export type MedigapFilterItem = {
  __typename?: 'MedigapFilterItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type MedigapFilterStateInput = {
  age: Scalars['Int']['input'];
  benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
  companies?: InputMaybe<Array<Scalars['String']['input']>>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  gender: Gender;
  id?: InputMaybe<Scalars['UUID']['input']>;
  monthlyPlanPremiumRanges?: InputMaybe<Array<Scalars['String']['input']>>;
  planCategory?: InputMaybe<Scalars['String']['input']>;
  planNames?: InputMaybe<Array<MedigapPlanName>>;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};

export type MedigapFilterStateOutput = {
  __typename?: 'MedigapFilterStateOutput';
  age: Scalars['Int']['output'];
  benefits?: Maybe<Array<MedigapPlanBenefit>>;
  companies?: Maybe<Array<Scalars['String']['output']>>;
  county?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['LocalDate']['output']>;
  gender: Gender;
  id: Scalars['UUID']['output'];
  monthlyPlanPremiumRanges?: Maybe<Array<Scalars['String']['output']>>;
  planCategory?: Maybe<Scalars['String']['output']>;
  planNames?: Maybe<Array<MedigapPlanName>>;
  tobacco: Scalars['Boolean']['output'];
  zip: Scalars['String']['output'];
};

export enum MedigapFrequencyPeriodOutput {
  HalfYearly = 'HALF_YEARLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type MedigapMaBestRatingOutput = {
  __typename?: 'MedigapMaBestRatingOutput';
  amBestRating?: Maybe<Scalars['String']['output']>;
  naic: Scalars['String']['output'];
  orgName?: Maybe<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
};

export enum MedigapPlanBenefit {
  ForeignTravelEmergency = 'FOREIGN_TRAVEL_EMERGENCY',
  PartADeductible = 'PART_A_DEDUCTIBLE',
  PartBDeductible = 'PART_B_DEDUCTIBLE',
  PartBExcessCharges = 'PART_B_EXCESS_CHARGES',
  SkilledNursingFacilities = 'SKILLED_NURSING_FACILITIES'
}

export enum MedigapPlanName {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  Hdf = 'HDF',
  Hdg = 'HDG',
  I = 'I',
  K = 'K',
  L = 'L',
  M = 'M',
  MaCore = 'MA_CORE',
  MaSupp1 = 'MA_SUPP1',
  MaSupp1A = 'MA_SUPP1A',
  MnrExcs = 'MNR_EXCS',
  MnrPrev = 'MNR_PREV',
  MnrPta = 'MNR_PTA',
  MnrPtb = 'MNR_PTB',
  MnrUhc1 = 'MNR_UHC1',
  MnrUprv = 'MNR_UPRV',
  MnBasic = 'MN_BASIC',
  MnExtb = 'MN_EXTB',
  MnHded = 'MN_HDED',
  MnHded2 = 'MN_HDED2',
  MnPbco = 'MN_PBCO',
  MnXbas = 'MN_XBAS',
  N = 'N',
  WiHded = 'WI_HDED'
}

export type MedigapPlanOutput = {
  __typename?: 'MedigapPlanOutput';
  amBestRating?: Maybe<Scalars['String']['output']>;
  duplicates?: Maybe<Array<MedigapPlanOutput>>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  householdDiscount?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  monthlyPremium?: Maybe<Scalars['String']['output']>;
  naic: Scalars['String']['output'];
  orgName?: Maybe<Scalars['String']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
  planName?: Maybe<Scalars['String']['output']>;
  ratingClass?: Maybe<Scalars['String']['output']>;
  showAdditionalBenefitsDescription: Scalars['Boolean']['output'];
  spRating?: Maybe<Scalars['String']['output']>;
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MedigapPlanRateOutput = {
  __typename?: 'MedigapPlanRateOutput';
  carrier?: Maybe<Scalars['String']['output']>;
  female65PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  female65PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  female75PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  female75PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  householdPercentMax?: Maybe<Scalars['BigDecimal']['output']>;
  householdPercentMin?: Maybe<Scalars['BigDecimal']['output']>;
  male65PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  male65PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
  male75PriceMax?: Maybe<Scalars['BigDecimal']['output']>;
  male75PriceMin?: Maybe<Scalars['BigDecimal']['output']>;
};

export enum MedigapPlansField {
  MonthlyPremium = 'MONTHLY_PREMIUM',
  PlanName = 'PLAN_NAME'
}

export type MedigapPlansFilterInput = {
  age: Scalars['Int']['input'];
  benefits?: InputMaybe<Array<MedigapPlanBenefit>>;
  companies: Array<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['LocalDate']['input']>;
  gender: Gender;
  monthlyPlanPremiumRanges: Array<Scalars['String']['input']>;
  planNames: Array<MedigapPlanName>;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};

export type MedigapPlansSortInput = {
  direction: Sort;
  field: MedigapPlansField;
};

export type MedigapPreferredDrugOutput = {
  __typename?: 'MedigapPreferredDrugOutput';
  dosageRxcui: Scalars['String']['output'];
  frequency: CalcDrugFrequencyPeriod;
  productRxcui: Scalars['String']['output'];
  purchaseFrequency: MedigapFrequencyPeriodOutput;
  quantity: Scalars['BigDecimal']['output'];
};

export type MedigapPremiumRange = {
  __typename?: 'MedigapPremiumRange';
  planName: MedigapPlanName;
  rangeDescription: Scalars['String']['output'];
};

export type MedigapQuoteInput = {
  cId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  medigapFilterState: MedigapFilterStateInput;
};

export type MedigapQuoteOutput = {
  __typename?: 'MedigapQuoteOutput';
  cId?: Maybe<Scalars['String']['output']>;
  clientId: Scalars['UUID']['output'];
  drugDeliveryType?: Maybe<MedigapDrugDeliveryType>;
  id: Scalars['UUID']['output'];
  medigapFilterState: MedigapFilterStateOutput;
  preferredDrugs: Array<MedigapPreferredDrugOutput>;
  preferredPharmacies: Array<Scalars['String']['output']>;
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createMedigapQuoteForClient: SaveMedigapQuoteOutput;
  createMedigapQuoteFromMedicareQuote: SaveMedigapQuoteOutput;
  saveMedigapFilterState: Scalars['UUID']['output'];
  saveMedigapQuote: SaveMedigapQuoteOutput;
};


export type MutationCreateMedigapQuoteForClientArgs = {
  agentOffer?: InputMaybe<Scalars['Boolean']['input']>;
  input: MedigapQuoteInput;
};


export type MutationCreateMedigapQuoteFromMedicareQuoteArgs = {
  medicareQuoteId: Scalars['UUID']['input'];
};


export type MutationSaveMedigapFilterStateArgs = {
  input: MedigapFilterStateInput;
};


export type MutationSaveMedigapQuoteArgs = {
  input: MedigapQuoteInput;
};

export type PageInput = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PageableMedigapPlanOutput = {
  __typename?: 'PageableMedigapPlanOutput';
  data: Array<MedigapPlanOutput>;
  hasNext: Scalars['Boolean']['output'];
  hasPrevious: Scalars['Boolean']['output'];
  isFirst: Scalars['Boolean']['output'];
  isLast: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Long']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PlanPriceByMonthOutput = {
  __typename?: 'PlanPriceByMonthOutput';
  month: Scalars['LocalDate']['output'];
  monthlyPremium?: Maybe<Scalars['BigDecimal']['output']>;
  parentOrgName?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  buildMedigapPlanAmBestRatingReport: Array<MedigapMaBestRatingOutput>;
  medigapBenefits: Array<MedigapFilterItem>;
  medigapCompanies: Array<MedigapCompanyFilterItem>;
  medigapCompaniesUnfiltered: Array<MedigapCompanyFilterItem>;
  medigapCompany?: Maybe<MedigapCompanyOutput>;
  medigapFilterState: MedigapFilterStateOutput;
  medigapMonthlyPlanPremium: Array<MedigapFilterItem>;
  medigapPlan: MedigapPlanOutput;
  medigapPlanNames: Array<MedigapFilterItem>;
  medigapPlanRates: Array<MedigapPlanRateOutput>;
  medigapPlans: PageableMedigapPlanOutput;
  medigapPlansUnfiltered: PageableMedigapPlanOutput;
  medigapPremiumRanges: Array<MedigapPremiumRange>;
  medigapQuote: MedigapQuoteOutput;
  planPricesByMonth: Array<PlanPriceByMonthOutput>;
};


export type QueryBuildMedigapPlanAmBestRatingReportArgs = {
  code: Scalars['String']['input'];
};


export type QueryMedigapCompaniesArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapCompaniesUnfilteredArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapCompanyArgs = {
  naic: Scalars['String']['input'];
};


export type QueryMedigapFilterStateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMedigapPlanArgs = {
  key: Scalars['String']['input'];
};


export type QueryMedigapPlanNamesArgs = {
  filter: MedigapPlansFilterInput;
};


export type QueryMedigapPlanRatesArgs = {
  planName: MedigapPlanName;
};


export type QueryMedigapPlansArgs = {
  filter: MedigapPlansFilterInput;
  page: PageInput;
  sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPlansUnfilteredArgs = {
  filter: MedigapPlansFilterInput;
  page: PageInput;
  sort?: InputMaybe<Array<MedigapPlansSortInput>>;
};


export type QueryMedigapPremiumRangesArgs = {
  age: Scalars['Int']['input'];
  gender: CsgGender;
  tobacco: Scalars['Boolean']['input'];
  zip: Scalars['String']['input'];
};


export type QueryMedigapQuoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryPlanPricesByMonthArgs = {
  planName: MedigapPlanName;
};

export type SaveMedigapQuoteOutput = {
  __typename?: 'SaveMedigapQuoteOutput';
  clientId: Scalars['UUID']['output'];
  mgQuoteId: Scalars['UUID']['output'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum UserRole {
  RoleAdmin = 'ROLE_ADMIN',
  RoleNone = 'ROLE_NONE',
  RoleTechStaff = 'ROLE_TECH_STAFF',
  RoleUnauthorized = 'ROLE_UNAUTHORIZED',
  RoleUser = 'ROLE_USER'
}
