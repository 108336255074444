import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { CollectInfoForm } from '../../components/CollectInfoForm';
import { SelectInput } from '@coverright/ui/inputs';


export function SignUpBirthdate() {
  const navigate = useNavigate();
  const [isValid, setIsValid] = React.useState(false);
  const [formik, setFormik] = React.useState<any>();


  const onSubmit = React.useCallback(() => {
    //if (isValid) {
      navigate('/sign-up/personal-info')
    //}
  }, [isValid])

  return (<Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
    <Box sx={{p: '40px', width: 1, maxWidth: 609}}>
      <Typography className={'bold'} align={'center'} sx={{fontSize: 26, lineHeight: '32px'}}>We’ll need some basic information</Typography>
      <div className={'h-40'} />

      <CollectInfoForm onValidChange={setIsValid}
                       direction={'column'}
                       setFormik={setFormik}
                       onSubmit={onSubmit}
                       forceOpen
                       showFilled
                       notRequired
                       fields={['birthDate', 'gender', 'tobacco']} />
      <Box sx={{height: '40px'}} />
      <div>
        <Box sx={{display: 'flex', gap: 1, alignItems: 'center', mb: 1}}>
          <img src={'/assets/img/question.svg'} width={24} height={24} />
          <Typography className={'fs-16 lh-24 bold'}>What is this information for?</Typography>
        </Box>
        <Typography sx={{color: '#4D4D4D'}} className={'fs-16 lh-24'}>Some private Medicare plan prices can vary by age and tobacco use. We’ll need this information to give you personalized quotes for Medicare Supplement. The information fields above are not needed for Medicare Advantage and Part D Prescription Drug Plans</Typography>
      </div>
    </Box>
      <Button sx={{minWidth: 250, maxWidth: 529, width: '100%', borderRadius: '8px'}}
              onClick={() => onSubmit()}
              variant={'contained'} size={'large'} color={'primary'} type="submit">Continue</Button>
    </Box>
  );
}

export default SignUpBirthdate;


