import {
  DrugCostOutput,
  MonthlyTotalCost,
} from '@coverright/data-access/types/medicare';
import {
  Box,
  Collapse,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import * as _ from 'lodash';
import { CaretDown } from '@phosphor-icons/react';
import moment from 'moment/moment';
import { ResponsiveTableColumn } from '@coverright/features';

export const DrugSummary = ({
  costs,
  total,
  monthlyCosts,
}: {
  costs: DrugCostOutput[];
  monthlyCosts?: MonthlyTotalCost[];
  total?: string;
}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedState, setExpandedState] = React.useState<{
    [key: string]: boolean;
  }>({});

  const getExpandedValue = (key: string): boolean =>
    typeof expandedState[key] === 'undefined' ? false : expandedState[key];
  const toggleExpanded = (key: string) => {
    const newValue =
      expandedState[key] === undefined ? true : !expandedState[key];
    setExpandedState({
      ...expandedState,
      [key]: newValue,
    });
  };

  const groupedCosts = React.useMemo(() => {
    return _.groupBy(costs, 'month');
  }, []);

  return (
    <div>
      <Box
        component={'table'}
        cellSpacing={0}
        cellPadding={8}
        sx={drugSummaryTableStyles}
      >
        <thead>
          <tr>
            <td colSpan={2}>Your summary by month</td>
            {!phone && <td>Qty</td>}
            {!phone && <td>Cost</td>}
            {!phone && <td>Coverage phase</td>}
          </tr>
        </thead>
      </Box>

      {Object.keys(groupedCosts).map((month, i) => (
        <React.Fragment key={month}>
          <Box
            component={'table'}
            cellSpacing={0}
            cellPadding={8}
            sx={{
              ...drugSummaryTableStyles,
              '&:hover td': { backgroundColor: 'rgba(248,246,242,0.32)' },
            }}
          >
            <tbody>
              <tr
                className={'medium pointer'}
                onClick={() => toggleExpanded(month)}
              >
                <td colSpan={2}>
                  <CaretDown
                    size={12}
                    color="#1C434F"
                    weight="bold"
                    className={'mr-15'}
                  />
                  {moment(month.toString(), 'M').format('MMMM')}
                </td>
                <td></td>
                <td>
                  {
                    monthlyCosts?.find((mc) => mc.month.toString() == month)
                      ?.amountString
                  }
                </td>
                {!phone && (
                  <td>
                    {_.upperFirst(
                      _.lowerCase(
                        monthlyCosts?.find((mc) => mc.month.toString() == month)
                          ?.stage
                      )
                    ) || ''}
                  </td>
                )}
              </tr>
            </tbody>
          </Box>
          <Collapse in={getExpandedValue(month)}>
            <Box
              component={'table'}
              cellSpacing={0}
              cellPadding={8}
              sx={{
                ...drugSummaryTableStyles,
                '& td': { borderBottom: '1px solid #B3B3B3' },
              }}
            >
              <tbody>
                {groupedCosts[month].map((cost, i) => (
                  <Box
                    component={'tr'}
                    sx={{ '& td': { color: '#666666' } }}
                    key={i}
                  >
                    <Box
                      component={'td'}
                      sx={{ pl: { sm: '36px' } }}
                      colSpan={2}
                    >
                      {cost.packageName}
                    </Box>
                    <td>{cost.numericCost}</td>
                    <td>{cost.cost}</td>
                    {!phone && <td></td>}
                  </Box>
                ))}
              </tbody>
            </Box>
          </Collapse>
        </React.Fragment>
      ))}
    </div>
  );

  /*
  return <ResponsiveTable columns={drugSummaryTableColumns}
                          rows={_.orderBy(costs, ['month', 'name'])}
                          footer={<tr>
                            <td colSpan={phone ? undefined : 4}><Typography sx={{fontWeight: 600}} className={'fs-14 lh-14'}>Total Annual Cost</Typography></td>
                            <td><Typography sx={{fontWeight: 600}} className={'fs-14 lh-14'} align={'right'}>{total}</Typography></td>
                          </tr>}
  />
   */
};

const drugSummaryTableStyles: SxProps = {
  tableLayout: 'fixed',
  width: 1,
  fontSize: 14,
  '& thead td': {
    backgroundColor: '#F8F6F2',
    fontWeight: 600,
  },
  '& tbody td': {
    backgroundColor: '#fff',
    verticalAlign: 'top',
    borderBottom: '1px solid #B3B3B3',
  },
};

const drugSummaryTableColumns: ResponsiveTableColumn<DrugCostOutput>[] = [
  {
    title: 'Month',
    value: (drug) =>
      moment()
        .set({ month: drug.month - 1 })
        .format('MMM'),
  },
  {
    title: 'Drug',
    value: (drug) => <div>{drug.packageName}</div>,
  },
  {
    title: 'Coverage phase',
    value: (drug) => _.upperFirst(_.lowerCase(drug.stage)) || '',
  },
  {
    title: 'Qty',
    value: (drug) => drug.numericCost,
  },
  {
    title: (
      <Typography
        sx={{ fontWeight: 600, textAlign: { sm: 'right' } }}
        className={'fs-14 lh-14'}
      >
        Cost
      </Typography>
    ),
    value: (drug) => (
      <Typography
        sx={{ fontWeight: 600 }}
        className={'fs-14 lh-14'}
        align={'right'}
      >
        {drug.cost}
      </Typography>
    ),
  },
];
