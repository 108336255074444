import { Box, Typography } from '@mui/material';
import PlanCard from '../plan-list/components/PlanCard';
import { CRPreloader } from '@coverright/ui/shared';
import React from 'react';
import { GetPlansYear, getQuoteId } from '@coverright/utils';
import MAMarketplaceLayout from '../../components/MAMarketplaceLayout';
import FavoritesSideContent from './FavoritesSideContent';
import { useMedicarePlans } from '@coverright/data-access/medicare';
import { FavoritesContext, QuoteContext } from '@coverright/shared/contexts';
import { Field, Plan } from '@coverright/data-access/types/medicare';
import { Sort } from '@coverright/data-access/types/medigap';
import ComparisonPanel from '../plan-comparison/components/ComparisonPanel';
import { Footer } from '@coverright/ui/marketplaces';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import { useParams, useNavigate } from 'react-router';

export function Favorites() {
  const {zip, countyName} = useParams<{ zip: string, countyName: string}>();
  const pageSize = 20;
  const [plans, setPlans] = React.useState<Plan[]>([]);
  const [page, setPage] = React.useState({
    page: 0,
    size: pageSize
  });
  const {id, preferredDrugs, planYear} = React.useContext(QuoteContext);
  const {favorites, toggleFavorite} = React.useContext(FavoritesContext);

  const [total, setTotal] = React.useState<number>();
  const [request, results] = useMedicarePlans({
    onCompleted: response => {
      setPlans([...plans, ...response.plans.data])
    }
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const quoteId = React.useMemo(() => {
    return id || getQuoteId();
  }, [id])

  React.useEffect(() => {
    if (hasMorePlans) {
      request({
        variables: {
          filter: {onlyFavorite: true, zip: zip!, countyName: countyName!},
          page,
          quoteId,
          sort: [
            { "field": Field.Premium,"direction":Sort.Asc },
            { "field": Field.MaxOutOfPocket, "direction": Sort.Asc},
            { "field": Field.Deductible, "direction": Sort.Asc},
            { "field": Field.Name, "direction": Sort.Asc}
          ]
        }
      })
    }
  }, [page]);

  const hasMorePlans = React.useMemo(() => {
    return !total || plans.length < total;
  }, [total, plans.length])

  React.useEffect( () => {
    if(results.data) {
      setTotal(results.data?.plans.totalElements)
    }

  }, [results.data]);

  const goToPlans = () => {
    navigate(-1);
  }

  return (<>
    <MAMarketplaceLayout
      source={ProfileSource.MaMarketplace}
      onBackButtonClick={goToPlans}
      title={<>Plans added to favorites <b>({plans.length})</b></>}
      backButtonTitle={'Back to plans'}
      side={<FavoritesSideContent />}
      main={<Box flexDirection={'column'} maxWidth={'800px'} alignItems={'center'}>
        <Box>
          {!!plans.length && plans.map(plan => <PlanCard plan={plan}
                                                         favorites={favorites}
                                                         toggleFavorite={toggleFavorite}
                                                         preferredDrugs={preferredDrugs}
                                                         zip={zip!}
                                                         planYear={planYear || GetPlansYear()}
                                                         countyName={countyName!}
                                                         key={plan.bidId} />)}
          {(!plans.length && !results.loading && results.called) && <Typography color={'textPrimary'}
                                                                                align={'center'}
                                                                                className={'fs-18 bold mt-50 mb-110'}>
            You don’t have any plans added to favorites. When reviewing plans, click the heart icon to save a plan that you like to your favorites.
          </Typography>}
          {results.loading &&
          <Box textAlign={"center"} my={2}>
            <CRPreloader />
          </Box>
          }
        </Box>
        <Footer />
      </Box>}
    />
    {zip && <ComparisonPanel countyName={countyName!} zip={zip!} />}
  </>);
}

export default Favorites;
