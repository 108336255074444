import React from "react";
import { Box, Divider, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { CRTooltip } from '@coverright/ui/inputs';

export type SimplePlanDetailProps = {
  title: string | JSX.Element,
  tooltip?: string,
  content?: string | null | JSX.Element,
  emptyString?: string,
  hideDivider?: boolean,
  thin?: boolean,
  dividerSx?: SxProps,
  sx?: SxProps,
  titleClassName?: string,
  imgClassName?: string,
}

function PlanDetail({title, content, tooltip, emptyString, thin, hideDivider, dividerSx, titleClassName, imgClassName, sx}: SimplePlanDetailProps) {

  return (
    <>
      <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: "flex-start", ...sx}}>
        <Box sx={{minWidth: {md: '50%'}, width: {md: '50%'}}}>
          {!tooltip && <Typography sx={{
            fontSize: '16px',
            mt: '4px',
            mr: '8px',
          }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>}
          {tooltip && <div className={`flex`}>
            <CRTooltip placement={'top'} arrow title={tooltip}>
              <div className={'flex flex-align-start'}>
                <img className={`mt-5 mr-8 ${imgClassName || ''}`} src={'/assets/img/info.svg'}></img>
                <Typography sx={{
                  fontSize: '16px',
                  mt: '4px',
                  mr: '8px',
                  fontWeight: '700',
                }} className={`${!thin ? 'bold' : 'regular'} ${titleClassName || ''}`} >{title}</Typography>
              </div>
            </CRTooltip>
          </div>}
        </Box>
        <Box display={"flex"} mt={{xs: '5px', md: 0}}>
          <Box display={"flex"} flexGrow={2} ml={{xs: 3, md: 0}}>
            {typeof content !== "string" && content}
            {typeof content === "string" &&
            <Typography className={'fs-12 lh-20'} color={"textPrimary"}>{content || emptyString}</Typography>}
          </Box>
        </Box>
      </Box>
      {!hideDivider && <Divider sx={{my: {xs: 2, md: 3}, ...dividerSx}}/>}
    </>
  )
}

export const SimplePlanDetail = React.memo(PlanDetail);
