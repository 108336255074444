import { CalendlyCallType, Client } from './types';
export function isCalendlyEvent(e: MessageEvent) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
}

export const addScriptToPage = () => {
  if (!document.querySelector('#calendly-js')) {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.id = 'calendly-js';
    document.body.appendChild(script);
  }
};

export const removeScriptFromPage = () => {
  document.querySelector('#calendly-js')?.remove();
};

export async function getCalendlyUrl(
  initialUrl = '',
  type = CalendlyCallType.consultation,
  agent?: {enrollmentMeetingUrl?: string | null, planReviewUrl?: string | null, b2bMeetingUrl?: string | null, consultationUrl?: string | null},
  client?: Client
) {
  if (initialUrl) {
    return decorate(initialUrl, client);
  }

  const isProduction = process.env.NX_PRODUCTION === 'true';
  if (!isProduction) {
    return decorate('https://calendly.com/benjamin811161-mailplus/30min');
  }

  let url = `https://calendly.com/coverright-advisors/medicare-consultation-client`;
  let typedUrl: string | undefined | null = '';

  switch (type) {
    case CalendlyCallType.enrollment:
      typedUrl = agent?.enrollmentMeetingUrl;
      break;
    case CalendlyCallType.planReview:
      typedUrl = agent?.planReviewUrl;
      break;
    case CalendlyCallType.b2b:
      typedUrl = agent?.b2bMeetingUrl;
      break;
  }

  url = typedUrl || agent?.consultationUrl || url

  return decorate(url, client);
}

const decorate = (url: string, client?: Client) =>
  `${url}?primary_color=1C434F${
    client?.firstName
      ? '&name=' +
        client?.firstName +
        ' ' +
        (client?.lastName || '')
      : ''
  }${client?.email ? '&email=' + client?.email : ''}${
    client?.phoneNumber ? '&a1=' + client?.phoneNumber : ''
  }`;
