import React from 'react';
import { PlanFilterContext } from './PlanFilterContext';
import { ListItem, PlanFilterDataContext } from './PlanFilterDataContext';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { CollapseSection, StyledRating, TooltipedTitle } from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';
import { CRCheckbox } from '@coverright/ui/inputs';
import { createStyles, makeStyles } from '@mui/styles';
import { getQuoteId, useDebouncedEffect } from '@coverright/utils';
import FilterItems from '../FilterItems';
import { DrugErrorModal, DsnpOffErrorModal, DsnpOnErrorModal } from './ErrorModals';
import * as _ from 'lodash';
import { ExtraBenefit, MedicareQuoteFilterExtraBenefit, Rating, SnpType } from '@coverright/data-access/types/medicare';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 16,
      marginTop: 5
    },
    link: {
      color: colors.custom.green.variant1,
      cursor: 'pointer',
      textDecoration: 'underline',
      marginTop: 5,
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    inputLabel: {
      fontSize: 14
    },
    input: {
      '& input': {
        padding: '18px 0 14px!important'
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    filterTitle: {
      fontSize: 14,
    },
    checkboxLabel: {
      fontSize: 12,
    }
  }),
);

export default function PlanFilter() {
  const classes = useStyles();
  const filter = React.useContext(PlanFilterContext)
  const [drugErrorOpen, setDrugErrorOpen] = React.useState(false);
  const [dsnpOnErrorOpen, setDsnpOnErrorOpen] = React.useState(false);
  const [dsnpOffErrorOpen, setDsnpOffErrorOpen] = React.useState(false);
  const [maxPremium, setMaxPremium] = React.useState('');
  const [maxOutOfPocket, setMaxOutOfPocket] = React.useState('');
  const [planTypes, setPlanTypes] = React.useState<ListItem[]>([]);
  const [snpTypes, setSnpTypes] = React.useState<ListItem[]>([]);
  const [companies, setCompanies] = React.useState<ListItem[]>([]);
  const [starRatings, setStarRatings] = React.useState<ListItem[]>([]);
  const [extraBenefits, setExtraBenefits] = React.useState<ListItem[]>([]);
  const [maxOutOfPocketRanges, setMaxOutOfPocketRanges] = React.useState<ListItem[]>([]);
  const [maxCostRanges, setMaxCostRanges] = React.useState<ListItem[]>([]);
  const [planTypeToAdd, setPlanTypeToAdd] = React.useState<any>();
  const [partBPremiumReductionCount, setPartBPremiumReductionCount] = React.useState<number>(0);

  const {getPlanFilterData, planFilterData} = React.useContext(PlanFilterDataContext);

  useDebouncedEffect(() => {
    if (filter.values) {
      getPlanFilterData({
        variables: {
          filter: filter.values,
          quoteId: getQuoteId() || null,
        }
      });
    }
  }, 800, [filter.values]);

  React.useEffect(() => {
    if (planFilterData) {
      setCompanies(planFilterData.companies);
      setPlanTypes(planFilterData.planTypes);
      setSnpTypes(planFilterData.SNPTypes);
      setExtraBenefits(planFilterData.extraBenefits);
      setMaxCostRanges(planFilterData.maxCostRanges);
      setMaxOutOfPocketRanges(planFilterData.maxOutOfPocketRanges);
      setStarRatings(planFilterData.starRatings);
      setPartBPremiumReductionCount(planFilterData.partBPremiumReductionCount);
      // window.scrollTo(0, 0)
    }
  }, [planFilterData]);

  const getRatingLabel = React.useCallback((defaultValue: number, ratings: Rating[]) => {
    const count = _.sum(starRatings
      .filter(i => ratings.includes(i.value))
      .map(i => {
        const matches = i.label.match(/\(\d\)/gm);
        if (matches) {
          return matches[0]
            .replace('(', '')
            .replace(')', '')
        }
        return '0';
      }).map(parseFloat)
    )

    return <Box display={'flex'} alignItems={'center'} gap={1}>
      <StyledRating readOnly defaultValue={defaultValue}/>
      <Typography className={'fs-12'}>({count})</Typography>
    </Box>
  }, [starRatings]);

  if (!filter.values) {
    return null;
  }

  return <Box width={'100%'} style={{background: "#fff"}} className={'plan-filters'}>
    <Typography className={'bold fs-20 lh-25'}>Filter your results</Typography>
    <Typography className={'fs-14 lh-17 underline pointer mt-10'} onClick={filter.reset} sx={{color: colors.custom.green.variant2}}>Reset filters</Typography>
    <Divider sx={{my: 2}} />

    <DrugErrorModal open={drugErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
      }
      setDrugErrorOpen(false);
    }} />

    <DsnpOnErrorModal open={dsnpOnErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.setFilterValues([
          {value: [], filterKey: 'planTypes'},
          {value: [SnpType.DSnp], filterKey: 'SNPTypes'},
        ])
      }
      setDsnpOnErrorOpen(false);
    }} />

    <DsnpOffErrorModal open={dsnpOffErrorOpen} onClose={(val: any) => {
      if (val) {
        const values: any = [
          {value: [], filterKey: 'SNPTypes'},
        ]
        if (planTypeToAdd) {
          values.push({value: [planTypeToAdd], filterKey: 'planTypes'})
        }
        filter.setFilterValues(values)
      }
      setPlanTypeToAdd(undefined);
      setDsnpOffErrorOpen(false);
    }} />

    {/*<Box marginTop={'29px'}>
      <CollapseSection title={<TooltipedTitle title={'Monthly Budget'} tooltip={''}/>}>
        <TextInput label={'Maximum cost per month'}
                       inputLabelClass={classes.inputLabel}
                       value={maxPremium}
                       onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                         setMaxPremium(event.target?.value as string)
                       }}
                       InputProps={{
                         startAdornment: <InputAdornment position="start">$</InputAdornment>,
                         endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                         classes: {root: classes.input}
                       }}
        />
        <Box mt={'32px'}>
          <Box mb={'16px'}>
            <TooltipedTitle title={'Max Out-of-Pocket'} tooltip={''}/>
          </Box>
          <TextInput label={'Total cap per year'}
                         inputLabelClass={classes.inputLabel}
                         value={maxOutOfPocket}
                         onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                           setMaxOutOfPocket(event.target?.value as string)
                         }}
                         InputProps={{
                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                           endAdornment: <InputAdornment position="end">per month</InputAdornment>,
                           classes: {root: classes.input}
                         }}
          />
        </Box>
      </CollapseSection>
    </Box>*/}
    <Box marginTop={'24px'}>
      {!!maxOutOfPocketRanges &&
      <FilterItemWithCheckbox items={maxOutOfPocketRanges}
                              tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'}
                              selectedKeys={filter.values.maxOutOfPocketRangeNames || []} onChange={filter.switchMaxOutOfPocketRanges}
                              title={'Max out-of-pocket'}/>
      }
    </Box>
    <Divider sx={{mt: 2}}/>
    <Box marginTop={'24px'}>
      {!!maxCostRanges &&
      <FilterItemWithCheckbox items={maxCostRanges}
                              tooltip={'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this form for a full description of the breakdown of these costs.'}
                              selectedKeys={filter.values.maxCostRangeNames || []} onChange={filter.switchMaxCostRanges}
                              title={'Estimated average total cost per month'}/>
      }
    </Box>
    <Divider sx={{mt: 2}}/>

    <Box marginTop={'16px'}>
      <CollapseSection buttonSx={{backgroundColor: 'white'}}
                       title={<TooltipedTitle labelSx={{fontSize: 14, fontWeight: 700, mt: '-2px'}} infoPosition={'start'} infoAlign={'start'}
                                              title={'CMS star rating ' + (filter?.values?.planYear || '2023').toString().replace('Year', '')} tooltip={'The Star Rating is an overall rating based on the plan\'s quality and performance across the different types of services that the plan offers. \n\n For plans covering health services, this includes an overall rating of the quality of services of 5 main categories: Staying healthy (screening tests and vaccines); Managing chronic (long-term) conditions; Member experience with the health plan; Member complaints and changes with the health plan\'s performance; and Health plan customer service. The data to determine these ratings is sourced from member surveys conducted by Medicare, information from clinicians, information submitted by plans, and results from Medicare\'s monitoring activities.'}/>}>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.R5}
                    disabled={!starRatings.find(i => i.value === Rating.R5) || starRatings.find(i => i.value === Rating.R5)?.disabled}
                    label={getRatingLabel(5, [Rating.R5])}
                    checked={filter.values.rating?.includes(Rating.R5)}
                    onChange={() => filter.switchRating(Rating.R5)}/>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.R4}
                    disabled={!starRatings.some(i => [Rating.R4, Rating.R4_5].includes(i.value)) || starRatings.filter(i => [Rating.R4, Rating.R4_5].includes(i.value))?.every(i => i.disabled)}
                    label={getRatingLabel(4, [Rating.R4, Rating.R4_5])}
                    checked={filter.values.rating?.includes(Rating.R4)}
                    onChange={() => filter.switchRating(Rating.R4)}/>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.R3}
                    disabled={!starRatings.some(i => [Rating.R3, Rating.R3_5].includes(i.value)) || starRatings.filter(i => [Rating.R3, Rating.R3_5].includes(i.value))?.every(i => i.disabled)}
                    label={getRatingLabel(3, [Rating.R3, Rating.R3_5])}
                    checked={filter.values.rating?.includes(Rating.R3)}
                    onChange={() => filter.switchRating(Rating.R3)}/>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.R2}
                    disabled={starRatings.filter(i => [Rating.R2, Rating.R2_5].includes(i.value))?.every(i => i.disabled)}
                    label={getRatingLabel(2, [Rating.R2, Rating.R2_5])}
                    checked={filter.values.rating?.includes(Rating.R2)}
                    onChange={() => filter.switchRating(Rating.R2)}/>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.R1}
                    disabled={!starRatings.some(i => [Rating.R1, Rating.R1_5].includes(i.value)) || starRatings.filter(i => [Rating.R1, Rating.R1_5].includes(i.value))?.every(i => i.disabled)}
                    label={getRatingLabel(1, [Rating.R1, Rating.R1_5])}
                    checked={filter.values.rating?.includes(Rating.R1)}
                    onChange={() => filter.switchRating(Rating.R1)}/>
        <CRCheckbox variant={'standart'}
                    controlClassName={classes.checkbox}
                    name={Rating.None}
                    disabled={!starRatings.some(i => [Rating.None].includes(i.value)) || starRatings.filter(i => [Rating.None].includes(i.value))?.every(i => i.disabled)}
                    label={<Typography className={'fs-12'}>{(starRatings.find(i => i.value === Rating.None)?.label || 'Not available (0)').replace('available', 'rated')}</Typography>}
                    checked={filter.values.rating?.includes(Rating.None)}
                    onChange={() => filter.switchRating(Rating.None)}/>
      </CollapseSection>
    </Box>
    {/*<Divider mt={'32px'}/>
    <Box marginTop={'24px'}>
      <CollapseSection title={<TooltipedTitle className={classes.filterTitle} title={'Referral Needed?'} tooltip={'A referral is a written order from your doctor (usually your primary care physician) for you to see a specialist doctor. Some Medicare Advantage plans (in particular, Health Maintenance Organization (HMO) plans) require you to receive a referral from your primary care physician before seeing a specialist. If you don\'t get a referral, you can be charged out of pocket for the specialist services.'}/>}>
        <CRCheckbox variant={'standart'} controlClassName={classes.checkbox} label="Yes" checked={true}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
        <CRCheckbox variant={'standart'} controlClassName={classes.checkbox} label="No" checked={false}
                        labelClassName={classes.checkboxLabel}
                        onChange={() => {
                        }}/>
      </CollapseSection>
    </Box>*/}
    <Divider sx={{mt: 2}}/>
    <Box marginTop={'16px'}>
      {!!planTypes &&
      <FilterItemWithCheckbox items={planTypes} selectedKeys={filter.values.planTypes || []}
                              onChange={type => {
                                if (!filter.values?.planTypes.length && filter.values?.SNPTypes.includes(SnpType.DSnp)) {
                                  setDsnpOffErrorOpen(true);
                                  setPlanTypeToAdd(type);
                                } else {
                                  filter.switchPlanType(type)
                                }
                              }}
                              tooltip={'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'}
                              title={'Plan type'}/>
      }
    </Box>
    <Divider sx={{mt: 2}}/>

    <Box marginTop={'16px'}>
      {!!snpTypes &&
      <FilterItemWithCheckbox items={snpTypes} selectedKeys={filter.values.SNPTypes || []}
                              onChange={type => {
                                if (filter.values?.SNPTypes.includes(SnpType.DSnp)) {
                                  setDsnpOffErrorOpen(true);
                                } else {
                                  setDsnpOnErrorOpen(true);
                                }
                              }}
                              tooltip={'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'}
                              title={'Special Needs Plans'}/>
      }
    </Box>

    <Divider sx={{mt: 2}}/>

    <Box marginTop={'16px'}>
      {!!extraBenefits &&
      <FilterItemWithCheckbox items={extraBenefits
                                      .filter(b => b.value === MedicareQuoteFilterExtraBenefit.DrugCoverage)
                                      .map(b => ({...b, label: b.label.replace('Drug coverage', 'Part D Drug Coverage')}))}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => {
                                if (filter.values?.extraBenefits.includes(ExtraBenefit.DrugCoverage)) {
                                  setDrugErrorOpen(true)
                                } else {
                                  filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
                                }
                              }}
                              tooltip={'Indicates whether this plan also includes bundled prescription drug coverage.'}
                              title={'Prescription Drug Coverage'}/>
      }
    </Box>

    <Divider sx={{mt: 2}}/>

    <Box marginTop={'16px'}>
      <FilterItemWithCheckbox items={[{label: `Part B Premium Reduction available (${partBPremiumReductionCount})`, value: MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback, disabled: !partBPremiumReductionCount}]}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => filter.switchExtraBenefits(type)}
                              tooltip={'A Part B premium reduction benefit is offered by some Medicare Part C (Medicare Advantage) plans.\n\nIf you enroll in a Medicare Advantage plan with this benefit, the insurance company will help pay some or all of your Part B monthly premium. The amount covered can range from 10 cents to the full Part B premium cost.'}
                              title={'Part B Premium Reduction'}/>
    </Box>

    <Divider sx={{mt: 2}}/>
    <Box marginTop={'16px'}>
      {!!companies &&
      <FilterItemWithCheckbox items={companies} selectedKeys={filter.values.companies || []}
                              onChange={type => filter.switchCompany(type)}
                              tooltip={'The name of the parent company issuing the plan.'}
                              title={'Company'}/>
      }
    </Box>

    <Divider sx={{mt: 2}}/>

    <Box marginTop={'16px'}>
      {!!extraBenefits &&
      <FilterItemWithCheckbox items={extraBenefits.filter(b => ![MedicareQuoteFilterExtraBenefit.DrugCoverage, MedicareQuoteFilterExtraBenefit.PartBPremiumGiveback, MedicareQuoteFilterExtraBenefit.InsulinSavings].includes(b.value))}
                              selectedKeys={filter.values.extraBenefits || []}
                              onChange={type => filter.switchExtraBenefits(type)}
                              tooltip={'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'}
                              title={'Extra benefits'}/>
      }
    </Box>
  </Box>
}


interface FilterItemWithCheckboxProps {
  items?: ListItem[],
  selectedKeys: string[],
  onChange: (key: any) => void,
  title: string,
  tooltip?: string
}

const FilterItemWithCheckbox = ({items, selectedKeys, onChange, title, tooltip}: FilterItemWithCheckboxProps) => {

  return <CollapseSection title={<TooltipedTitle labelSx={{fontSize: 14, fontWeight: 700, mt: '-2px'}} infoPosition={'start'} infoAlign={'start'} title={title} tooltip={tooltip || ''}/>} buttonSx={{backgroundColor: 'white'}}>
    <FilterItems onChange={onChange} selectedKeys={selectedKeys} items={items}/>
  </CollapseSection>
}
