import React from "react";
import {
  Box, Button
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Footer } from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { ComparisonContext } from '../../ComparisonContext';
import PlanDetailsContent from '../detailed-plan/components/PlanDetailsContent';
import { getQuoteId } from '@coverright/utils';
import MAMarketplaceLayout from '../../components/MAMarketplaceLayout';
import ComparisonTable from './components/ComparisonTable';
import { useMedicarePlansByBid } from '@coverright/data-access/medicare';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import { QuoteContext } from '@coverright/shared/contexts';
import { MarketplaceLayoutContext } from '../../components/MarketplaceLayout';

export default function PlanComparison() {
  const {zip, countyName} = useParams<{ zip: string, countyName: string }>();
  const {list} = React.useContext(ComparisonContext);
  const {planYear, id} = React.useContext(QuoteContext);
  const [inputPlans, setInputPlans] = React.useState<any[]>([]);
  const {plans} = useMedicarePlansByBid(inputPlans);
  const navigate = useNavigate();


  React.useEffect(() => {
    if (!list.length) {
      navigate('/plans/' + zip + '/' + countyName)
    } else {
      if (!getQuoteId()) {
        setInputPlans(list.map(plan => ({ bidId: plan, zip, countyName, quoteId: getQuoteId()})))
      } else if (id) {
        setInputPlans(list.map(plan => ({ bidId: plan, zip, countyName, quoteId: getQuoteId(), planYear})))
      }
    }
  }, [list, planYear, id]);

  if (!plans.length) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <CRPreloader />
      <Footer/>
    </Box>
  }

  return <MAMarketplaceLayout
      source={ProfileSource.MaMarketplace}
      align={'flex-start'}
      onBackButtonClick={() => navigate(`/plans/${zip}/${countyName}`)}
      title={<>You’re comparing  <b>{plans.length} Medicare Advantage Plans</b></>}
      wrapperSx={{width: {xs: '100%', md: 'unset'}}}
      headerSx={{width: {md: '100vw'}, boxSizing: {md: 'border-box'}, position: {md: 'sticky'}, left: {md: 0}}}
      titleSx={{position: 'sticky', left: 16, width: 'fit-content'}}
      backButtonComponent={<BackButtonComponent onBackClick={() => navigate(`/plans/${zip}/${countyName}`)} />}
      extra={<div className={'h-24'} />}
      backButtonTitle={'Back to plans'}
      side={<PlanDetailsContent plan={plans[0]} zip={zip!} countyName={countyName!}/>}
      maxWidth={'100%'}
      main={<Box position={'relative'} sx={{width: '100%'}}>
        {!!plans.length && <>
          <ComparisonTable plans={plans} zip={zip!} countyName={countyName!} />
        </>}
        <Box display={"flex"} flex={1} justifyContent={"center"}>
          <Footer sx={{maxWidth: '100%'}}/>
        </Box>
      </Box>}
    />;
}

const BackButtonComponent = (props: {onBackClick: () => void}) => {
  const {setDrawerOpen} = React.useContext(MarketplaceLayoutContext);

  return <Box sx={{
    position: 'sticky',
    top: 0,
    boxSizing: 'border-box',
    background: '#f5f7f6',
    zIndex: 10,
    left: 0,
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }}>
    <Button
      sx={{color: colors.text.primary}}
      startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
      onClick={props.onBackClick}>
      Back to plans
    </Button>
    <Button
      sx={{color: colors.text.primary, display: {md: 'none'}}}
      startIcon={<img src={"/assets/img/contents.svg"}/>}
      onClick={() => setDrawerOpen(true)}>
      Table of Contents
    </Button>
  </Box>
}
