import React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Box, Fade, IconButton, Slide, Theme, Typography} from "@mui/material";
import { ComparisonContext } from '../../../ComparisonContext';
import { getCompanyLogo } from '@coverright/utils';
import { colors } from '@coverright/ui/themes';
import { Plan } from '@coverright/data-access/types/medicare';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: '1px solid ' + colors.custom.green.variant2,
      height: 81,
      width: 202,
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        marginBottom: 15,
        width: '100%',
      },
    },
    card: {
      background: 'white',
      height: '100%',
      width: '100%',
      padding: '10px',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      position: 'relative',
      overflow: 'hidden',
      boxSizing: 'border-box'
    },
    icon: {
      position: 'absolute!important' as any,
      top: 0,
      right: 0,
      padding: 8,
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: 182,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    logo: {
      width: 86,
      height: 24,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    }
  }),
);

type ComparisonCardProps = {
  plan?: Plan
}

export default function ComparisonCard(props: ComparisonCardProps) {
  const classes = useStyles();

  return <div className={classes.container}>
    {props.plan && <Card plan={props.plan} /> }
  </div>
}

function Card(props: {plan: Plan}) {
  const classes = useStyles();
  const {removeFromCompare} = React.useContext(ComparisonContext);
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    setShow(true);
  }, [props.plan])

  return <Slide direction={'up'} in={show}><div className={classes.card}>
    <IconButton onClick={() => {
      setShow(false);
      removeFromCompare(props.plan.bidId)
    }} className={classes.icon}><img src={'/assets/img/close.svg'} /></IconButton>

    <div className={classes.logo} style={{backgroundImage: `url("${getCompanyLogo(props.plan.parentOrgName, props.plan.orgName)}")`}}  />
    <Typography className={classes.name + ' fs-14 lh-11'}>{props.plan.planName}</Typography>
    <Typography className={'medium fs-14 lh-11'}>{props.plan.monthlyPremium} Monthly cost</Typography>
  </div></Slide>
}
