import { CRTooltip } from "@coverright/ui/inputs";
import React from "react";
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material';


interface Props {
  title: string | JSX.Element,
  tooltip?: string,
  className?: string,
  infoPosition?: 'start' | 'end',
  infoAlign?: 'start' | 'center',
  labelSx?: SxProps,
  containerClassName?: string,
  imgSrc?: string,
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
}

export const TooltipedTitle = React.memo((props: Props) => (
  <div className={`flex ${props.containerClassName || ''}`}>
    <CRTooltip placement={'top'} arrow title={props.tooltip || ''}>
      <Box className={`flex ${props.infoAlign !== 'start' ? 'flex-align-center' : 'flex-align-start'}`} gap={1}>

        {(props.tooltip && props.infoPosition === 'start') &&
          <img {...props.imgProps} className={`w-14 h-14 ${props.imgProps?.className || ''}`}
               src={props.imgProps?.src || props.imgSrc || '/assets/img/info.svg'}></img>}


        {typeof props.title === 'string' && <Typography className={props.className} sx={{
          fontSize: '12px',
          fontWeight: '700',
          color: '#1C434F',
          ...props.labelSx
        }}>{props.title}</Typography>}

        {typeof props.title !== 'string' && <>{props.title}</>}


        {(props.tooltip && props.infoPosition !== 'start') &&
          <img {...props.imgProps} className={`w-14 h-14 ${props.imgProps?.className || ''}`}
               src={props.imgProps?.src || props.imgSrc || '/assets/img/info.svg'}></img>}
      </Box>
    </CRTooltip>
  </div>
))
