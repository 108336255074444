import React from 'react';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { useNavigate } from "react-router-dom";
import { AnimationContext } from '@coverright/shared/contexts';

export interface PageWrapperProps extends React.PropsWithChildren<any>{
  title: string,
  formName: string,
  subtitle?: string | JSX.Element,
  hideHeader?: boolean,
  hideBackButton?: boolean,
  hideNextButton?: boolean,
  nextButtonDisabled?: boolean,
  onBackButtonClick?: () => void,
  onNextButtonClick?: () => void,
  nextButtonTitle?: string,
  backButtonTitle?: string,
  hideFooter?: boolean,
  hideDisclamer?: boolean,
  loading?: boolean,
  maxWidth?: number | string,
  nextButtonExtra?: JSX.Element,
  nextButtonSx?: SxProps,
  backButtonExtra?: JSX.Element,
  sx?: SxProps,
  headerSx?: SxProps,
}

export function PageWrapper (props: PageWrapperProps) {
  const navigate = useNavigate();
  const {reverse} = React.useContext(AnimationContext)

  React.useEffect(() => {
    if (props.formName) {
      (window as any).gtm_step_name = props.formName;
      if ((window as any).track) {
        (window as any).track('Page view - ' + props.formName);
      }
    }
  }, [props.formName])

  return <Box width={'100%'}>
    <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
      <Box sx={{pb: '96px', width: 1, maxWidth: props.maxWidth || 700, ...props.sx}}>
        {!props.hideHeader && <Box sx={props.headerSx}>
          <Typography className={'bold'} sx={{fontSize: {xs: 28, md: 40}, whiteSpace: 'break-spaces', lineHeight: {xs: '35px', md: '50px'}}}>{props.title}</Typography>
          {props.subtitle && <Typography className={'fs-22 bold mt-12 lh-27'} color={colors.custom.green.variant2}>{props.subtitle}</Typography>}
        </Box>}

        <div className={'h-20'} />

        {props.children}

        {!props.hideFooter && <>
          <Divider sx={{borderColor: '#7B8F95'}} className={'mb-35'} />

          <Box sx={{
            display: 'flex',
            width: 1,
            gap: '12px',
            justifyContent: 'space-between',
            alignItems: {xs: 'center', md: 'flex-start'},
            flexDirection: {xs: 'column-reverse', md: 'row'}
          }}>
            <div>
              {!props.hideBackButton && <Button variant={'contained'} size={'large'} color={'grey'} className={'regular w-150 fs-16'} onClick={() => {
                reverse && reverse();
                props.onBackButtonClick ? props.onBackButtonClick() : navigate(-1);
              }}>{props.backButtonTitle || 'Back'}</Button>}
              {props.backButtonExtra && <div className={'mt-8'}>{props.backButtonExtra}</div>}
            </div>
            <div>
              {!props.hideNextButton && <Button className={'gtm-step-event'} id={props.formName}
                                                data-test="next_button"
                                                sx={{minWidth: 250, maxWidth: 250, ...props.nextButtonSx}}
                                                startIcon={props.loading && <CircularProgress size={15} sx={{color: 'white'}} />}
                                                disabled={props.nextButtonDisabled}
                                                onClick={props.onNextButtonClick}
                                                variant={'contained'} size={'large'} color={'primary'} type="submit">{props.nextButtonTitle || 'Continue'}</Button>}
              {props.nextButtonExtra && <div className={'mt-8'}>{props.nextButtonExtra}</div>}
            </div>
          </Box>
        </>}
        {!props.hideDisclamer && <Box sx={{margin: 'auto', mt: '100px'}}>
          <Typography align={'center'} sx={{margin: 'auto', maxWidth: 800}} variant={'h5'} color={'textPrimary'}>
            We do not offer every plan available in your area. Any information we provide is limited to those plans we do offer in your area. Please contact Medicare.gov or 1-800-MEDICARE to get information on all of your options.
          </Typography>
        </Box>}

      </Box>
    </Box>
  </Box>
}
