import * as React from 'react';
import {
  Stack,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Collapse,
} from '@mui/material';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import { getCompanyLogo, GetPlansYear } from '@coverright/utils';
import { useContext, useMemo } from 'react';
import { CompanyLogo, StarRating } from '@coverright/ui/shared';
import { Chart } from '../chart';
import Coverage from '../coverage';
import { CRSubtitle } from '@coverright/ui/marketplaces';
import {
  CoveragePhaseTable,
  DrugSummaryLegacy,
  DrugTable,
} from '../drug-tables';
import { QuoteContext } from '@coverright/shared/contexts';

type Props = {
  plan: IDrugCostBreakdownPlan;
  loading: boolean;
  onFullYearChange: (value: boolean) => void;
  fullYear: boolean;
  regulatory?: RegulatoryVariable;
};

export default function LegacyBreakdown(props: Props) {
  const { plan, onFullYearChange, fullYear, loading } = props;
  const [expanded, setExpanded] = React.useState(true);
  const { preferredDrugs } = useContext(QuoteContext);

  const planYear = React.useMemo(() => {
    if (plan?.planYear) {
      return plan.planYear.toString().replace('Year', '');
    }
    return GetPlansYear().toString().replace('Year', '');
  }, [plan]);

  const costs = useMemo(() => {
    return (
      plan?.drugCostMonthlyBreakdown?.costs.map((c) => ({
        ...c,
        numericCost:
          plan?.drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity || 0,
        packageName:
          plan?.drugPrices.find((p) => p.rxcui === c.rxcui)
            ?.packageDescription || c.packageName,
      })) || []
    );
  }, [plan]);

  const drugPrices = useMemo(() => {
    return plan?.drugPrices || [];
  }, [plan]);

  return (
    <Stack spacing={2}>
      <div>
        <StarRating
          plan={plan}
          size={18}
          endAdornment={
            <Typography sx={{ ml: 1 }} className={'medium fs-12'}>
              ({planYear})
            </Typography>
          }
        />

        <Box
          sx={{
            display: 'flex',
            alignItems: { sm: 'center' },
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <div>
            <Typography className={'bold fs-20 lh-25'}>
              {plan.planName}
            </Typography>
          </div>
          <CompanyLogo
            sx={{ backgroundPosition: 'center' }}
            url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
          />
        </Box>
      </div>

      <Divider />

      <div>
        <CRSubtitle
          title={'Estimated Monthly Cost Summary'}
          sx={{ justifyContent: 'center', width: 'auto' }}
        />
        <div className={'relative'}>
          <FormControlLabel
            labelPlacement="start"
            sx={{
              position: 'absolute',
              right: 0,
              display: { xs: 'none', md: 'inline-flex' },
            }}
            onChange={(event, checked) => props.onFullYearChange(checked)}
            control={<Switch checked={props.fullYear} />}
            label={<Typography className={'medium'}>Full year</Typography>}
          />
          <Typography align={'center'} className={'fs-14 mb-8'}>
            Average monthly cost: <b>{plan?.drugsCost}</b>
          </Typography>
        </div>
        <Chart
          costs={costs}
          monthlyCosts={plan.drugCostMonthlyBreakdown?.monthlyCosts || []}
          colors={[
            '#1E95A0',
            '#1DB399',
            '#C8E8EB',
            '#7DA8AC',
            '#84C7BB',
            '#C3D4D6',
          ]}
          loading={loading}
        />
      </div>

      <Coverage plan={plan} />

      <CRSubtitle
        sx={{
          py: { xs: 2, md: 1 },
          pl: { xs: 1, md: 2 },
          pr: 1,
          background: '#FAFBFB',
        }}
        titleClassName={'bold text-primary'}
        title={'See detailed cost calculations'}
        expanded={expanded}
        onToggleClick={() => setExpanded(!expanded)}
      />

      <Collapse in={expanded}>
        <Stack spacing={2}>
          <DrugTable drugPrices={drugPrices} />

          <CoveragePhaseTable drugPrices={drugPrices} />

          <DrugSummaryLegacy
            costs={costs}
            drugPrices={drugPrices}
            preferredDrugs={preferredDrugs}
            monthlyCosts={plan?.drugCostMonthlyBreakdown?.monthlyCosts}
            total={plan?.drugCostMonthlyBreakdown?.totalClientCost}
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
