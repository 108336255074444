import React from "react";
import {
  ClickAwayListener, Hidden,
  styled, Tooltip,
  tooltipClasses,
  TooltipProps
} from '@mui/material';

export const CRTooltip = styled(({ className, ...props }: TooltipProps & {className?: any}) => {
  const [open, setOpen] = React.useState(false);
  const handleClickAway = () => {
    setOpen(false)
  }
  return <>
    <Hidden smDown>
      <Tooltip {...props} classes={{ popper: className }}/>
    </Hidden>
    <Hidden smUp>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Tooltip {...props} open={open} onClick={() => {
          setOpen(true)
        }} classes={{ popper: className }}/>
      </ClickAwayListener>
    </Hidden>
  </>
})({
  [`& .${tooltipClasses.tooltip}`]: {
    border: `1px solid #029094!important`,
    backgroundColor: '#f2f9fa',
    borderRadius: 4,
    color: '#1C434F',
    fontSize: 16,
    textAlign: 'left',
    padding: '15px 20px 11px',
    fontWeight: 400,
    '& .MuiTooltip-arrow': {
      color: '#f2f9fa'
    },
    '& .MuiTooltip-arrow:before': {
      border: `1px solid #029094!important`
    }
  }
});

