import {
  ResponsiveTable,
  ResponsiveTableColumn,
  useIsV3Theme,
} from '@coverright/features';
import { DrugPriceOutput } from '@coverright/data-access/types/medicare';
import { Benefit } from '@coverright/ui/marketplaces';
import * as React from 'react';

export const DrugTable = ({
  drugPrices,
}: {
  drugPrices: DrugPriceOutput[];
}) => {
  const isV3 = useIsV3Theme();
  const columns = isV3 ? drugTableColumns : legacyDrugTableColumns;
  return <ResponsiveTable columns={columns} rows={drugPrices} />;
};

const drugTableColumns: ResponsiveTableColumn<DrugPriceOutput>[] = [
  {
    title: 'Drug',
    value: (drug) => drug.packageDescription,
  },
  {
    title: 'Quantity',
    value: (drug) => drug.quoteQuantity,
  },
  {
    title: 'Estimated full retail price',
    value: (drug) => (
      <>
        {drug.price} {drug.isAggregatedPrice ? <sup>*</sup> : ''}
      </>
    ),
  },
  {
    title: 'Coverage',
    value: (drug) => (drug.isCoveredByPlan ? 'Yes' : 'No'),
  },
  {
    title: 'Quantity limit',
    value: (drug) => drug.quantityLimit || '-',
  },
  {
    title: 'Branded or generic?',
    value: (drug) => (drug.isGeneric ? 'Generic' : 'Branded'),
  },
];

const legacyDrugTableColumns: ResponsiveTableColumn<DrugPriceOutput>[] = [
  {
    title: 'Your drugs',
    value: (drug) => drug.name,
  },
  {
    title: 'Qty',
    value: (drug) => drug.quoteQuantity,
  },
  {
    title: 'Package',
    value: (drug) => drug.packageDescription,
  },
  {
    title: 'Covered under this plan?',
    value: (drug) => (
      <Benefit
        label={drug.isCoveredByPlan ? 'Covered' : 'Not covered'}
        value={drug.isCoveredByPlan}
      />
    ),
  },
  {
    title: 'Quantity limits under this plan?',
    value: (drug) => drug.quantityLimit || '-',
  },
  {
    title: 'Branded or generic?',
    value: (drug) => (drug.isGeneric ? 'Generic' : 'Branded'),
  },
];
