import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import { SnackbarProvider } from 'notistack';
import { environment } from './environments/environment';
import { Provider } from 'use-http'
import { createRoot } from 'react-dom/client';
import { initKeycloak } from '@coverright/shared/keycloak';

const root = createRoot(document.getElementById('root') as HTMLElement)

initKeycloak().then(() => {
  root.render(
    <StrictMode>
      <BrowserRouter>
        <SnackbarProvider preventDuplicate classes={{
          variantSuccess: 'success-snack'
        }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3} autoHideDuration={6000}>
          <Provider url={environment.userFlowRestUrl}>
            <App />
          </Provider>
        </SnackbarProvider>
      </BrowserRouter>
    </StrictMode>
  )
})
