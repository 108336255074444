import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Box, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { SelectInput, V3Checkbox } from '@coverright/ui/inputs';
import { useMemo } from 'react';

interface DCBFiltersProps {
  plan: IDrugCostBreakdownPlan;
  selectedDrugs: string[];
  onChange: (drugs: string[]) => void;
  fullYear: boolean;
  onFullYearChange: (value: boolean) => void;
}

export default function Filters(props: DCBFiltersProps) {
  const { plan, selectedDrugs, onChange, onFullYearChange, fullYear } = props;

  const planDrugs = useMemo(() => {
    return plan?.drugPrices || [];
  }, [plan]);

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'flex-start', sm: 'center' }}
    >
      <Typography className={'fs-18'}>Filter</Typography>
      <SelectInput
        variant="outlined"
        multiple
        hideTick
        renderValue={(v) => {
          if (!selectedDrugs.length) {
            return 'All drugs selected';
          } else if (selectedDrugs.length === 1) {
            return planDrugs.find((d) => d.rxcui === selectedDrugs[0])
              ?.packageDescription;
          }
          return selectedDrugs.length + ' drugs selected';
        }}
        className={'mb-0 w-250'}
        value={selectedDrugs}
        onChange={(e) => {
          onChange(e.target.value as any);
        }}
      >
        {planDrugs.map((d) => (
          <MenuItem key={d.rxcui} value={d.rxcui}>
            <V3Checkbox
              variant={'standart'}
              label={''}
              wrapperSx={{ maxWidth: 50 }}
              checked={selectedDrugs.includes(d.rxcui)}
            />
            <ListItemText primary={d.packageDescription} />
          </MenuItem>
        ))}
      </SelectInput>
      <SelectInput
        variant="outlined"
        multiple
        hideTick
        className={'mb-0 w-250'}
        renderValue={(v) => (fullYear ? 'View Full Year' : 'Not Full Year')}
        value={[]}
        onChange={(e) => onFullYearChange(!fullYear)}
      >
        <MenuItem>
          <V3Checkbox
            variant={'standart'}
            label={''}
            wrapperSx={{ maxWidth: 50 }}
            checked={fullYear}
          />
          <ListItemText primary={'View Full Year'} />
        </MenuItem>
      </SelectInput>
      <Box flex={1} />
    </Stack>
  );
}
