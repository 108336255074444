import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { client } from '@coverright/data-access/apollo-clients';
import * as React from 'react';

export function useZipCountyFips(options?: LazyQueryHookOptions<ICityResponse, {zip: string}>) {
  return useQuery<ICityResponse, {zip: string}>(gql(query), {
    client,
    fetchPolicy: "no-cache",
    ...options
  });
}

export function useZipCountyFipsLazy(options?: LazyQueryHookOptions<ICityResponse, {zip: string}>) {
  return useLazyQuery<ICityResponse, {zip: string}>(gql(query), {
    fetchPolicy: "no-cache",
    client,
    ...options
  });
}

const useIsSpecificState = (state: string) => (zip?: string) => {

  const [request, {data}] = useZipCountyFipsLazy();

  const result = React.useMemo(() => {
    return data?.plans_zip_county_fips[0].state
  }, [data])

  React.useEffect(() => {
    if (zip) {
      request({variables: {zip}})
    }
  }, [zip])

  return result ? result === state : undefined;
}

export const useIsMAState = useIsSpecificState('MA');
export const useIsWIState = useIsSpecificState('WI');

export interface ICityResponse {
  plans_zip_county_fips: IZipCountyFips[]
}

export interface IZipCountyFips {
  city: string,
  state: string,
  countyname: string
}

const query = `
query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`
