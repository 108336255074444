import { AppBar as MuiAppBar, Box, IconButton, SwipeableDrawer, Toolbar } from '@mui/material';
import * as React from 'react';
import { List, X } from '@phosphor-icons/react';
import { styled } from '@mui/material/styles';

interface Props {
  variant: 'light' | 'dark',
  slots: {
    logo: React.JSX.Element,
    left?: React.JSX.Element,
    right?: React.JSX.Element,
    drawerContent: React.JSX.Element,
  }
}

export function AppBar({ variant, slots }: Props) {
  const [open, setOpen] = React.useState(false);

  return <>
    <SwipeableDrawer
      anchor={'left'}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Toolbar sx={{ p: '29px 40px', m: 0 }}>
        <Box sx={{ display: 'flex', width: 1, justifyContent: 'space-between', alignItems: 'center' }}>
          {slots.logo}
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            id={'app-bar-close-menu-button'}
            sx={{ display: { lg: 'none' }, ml: '20px' }}
            onClick={() => setOpen(false)}
          >
            <X size={32} color="000"/>
          </IconButton>
        </Box>
      </Toolbar>
      <Box sx={{ p: '23px 32px' }}>
        {slots.drawerContent}
      </Box>
    </SwipeableDrawer>


    <MuiAppBar>
      <Toolbar sx={{ p: { xs: '29px 40px', md: '24px 72px', xl: 3 }, backgroundColor: variant === 'dark' ? '#1C434F' : '#F8F6F2' }}>
        <ContentWrapper>
          <Content>
            {slots.logo}
            {slots.left}
          </Content>
          <Content>
            <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
              {slots.right}
            </Box>

            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              id={'app-bar-toggle-menu-button'}
              sx={{ display: { lg: 'none' }, ml: '20px' }}
              onClick={() => setOpen(true)}
            >
              <List size={32} color="1C434F"/>
            </IconButton>
          </Content>
        </ContentWrapper>
      </Toolbar>
    </MuiAppBar>
  </>;
}

const Content = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 1
})

const ContentWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
})
