import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import { useIsV3Theme } from '@coverright/features';
import V3Breakdown from './v3';
import LegacyBreakdown from './legacy';

type Props = {
  plan: IDrugCostBreakdownPlan;
  loading: boolean;
  onFullYearChange: (value: boolean) => void;
  fullYear: boolean;
  regulatory?: RegulatoryVariable;
};

export default function Breakdown(props: Props) {
  const isV3 = useIsV3Theme();

  return isV3 ? <V3Breakdown {...props} /> : <LegacyBreakdown {...props} />;
}
