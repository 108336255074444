import { PlanYear } from '@coverright/data-access/types/medicare';
import { GetPlansYear } from '@coverright/utils';

// todo has another copy in MedigapPlanData because of compile error - need to fix
const deductibles: {[year: number]: Deductible} = {
  2022: {
    inpatientHospitalDeductible: '$1,556',
    dailyCoinsurance61_90: '$389',
    lifetimeReserveDays: '$778',
    skilledNursingFacility: '$194.50',
    annualPartBDeductible: '$233',
    standardPartBMonthlyPremium: '$170.10',
    initialCoverageLimit: '$4,430',
    catastrophicCoverageLimit: '$7,050',
    deductible: '$2,490',
  },
  2023: {
    inpatientHospitalDeductible: '$1,600',
    dailyCoinsurance61_90: '$400',
    lifetimeReserveDays: '$800',
    skilledNursingFacility: '$200.00',
    annualPartBDeductible: '$226',
    standardPartBMonthlyPremium: '$164.90',
    initialCoverageLimit: '$4,660',
    catastrophicCoverageLimit: '$7,400',
    deductible: '$2,700',
  },
  2024: {
    inpatientHospitalDeductible: '$1,632',
    dailyCoinsurance61_90: '$408',
    lifetimeReserveDays: '$816',
    skilledNursingFacility: '$204.00',
    annualPartBDeductible: '$240',
    standardPartBMonthlyPremium: '$174.70',
    initialCoverageLimit: '$5,030',
    catastrophicCoverageLimit: '$8,000',
    deductible: '$2,800',
  },
}

export const getDeductible = (key: keyof Deductible, year?: number | PlanYear | null) => {
  if (year && !Number.isInteger(year)) {
    year = Number(year.toString().replace('Year', ''))
  }
  return deductibles[year as number || parseFloat(GetPlansYear().toString().replace('Year', ''))][key];
}

interface Deductible {
  inpatientHospitalDeductible: string;
  dailyCoinsurance61_90: string;
  lifetimeReserveDays: string;
  skilledNursingFacility: string;
  annualPartBDeductible: string;
  standardPartBMonthlyPremium: string;
  initialCoverageLimit: string;
  catastrophicCoverageLimit: string;
  deductible: string;
}
